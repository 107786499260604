// pages/shops/index.js
import React, { useState } from 'react';
import {
  Card,
  Table,
  Button,
  Space,
  Tag,
  Image,
  Typography,
  Row,
  Col,
  Input,
} from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const ShopsIndex = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const columns = [
    {
      title: 'لوگو',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => (
        <Image
          src={logo}
          alt="shop logo"
          width={50}
          height={50}
          style={{ objectFit: 'cover' }}
        />
      ),
    },
    {
      title: 'نام فروشگاه',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'نوع',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <Tag color="blue">{type}</Tag>,
    },
    {
      title: 'تلفن',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'آدرس',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'کمیسیون',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => `${commission}%`,
    },
    {
      title: 'عملیات',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => navigate(`/shops/edit/${record.id}`)}
          >
            ویرایش
          </Button>
          <Button 
            danger 
            icon={<DeleteOutlined />}
          >
            حذف
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Title level={4}>
            <ShopOutlined /> مدیریت فروشگاه‌ها
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/shops/add')}
          >
            افزودن فروشگاه جدید
          </Button>
        </Col>
      </Row>

      <Input
        placeholder="جستجو در فروشگاه‌ها..."
        prefix={<SearchOutlined />}
        style={{ width: 300, marginBottom: 16 }}
        onChange={(e) => setSearchText(e.target.value)}
      />

      <Table columns={columns} />
    </Card>
  );
};

export default ShopsIndex;