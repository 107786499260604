import { BrowserRouter, Routes, Route, useParams, useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import fa_IR from "antd/lib/locale/fa_IR";
import MainLayout from "./component/layout/MainLayout";
import Login from "./pages/auth/Login";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./pages/auth/PrivateRoute";
import RoleManagement from "./pages/roles/RoleManagement";
import UserManagement from "./pages/users/UserManagement";
import Dashboard from "./component/dashboard/dashboard";
import Unauthorized from "./component/unauthorized/unauthorized";
import CategoryAdd from "./pages/categories/CategoryAdd";
import CategoryEdit from "./pages/categories/CategoryEdit";
import CategoryTree from "./pages/categories/CategoryTree";
import CategoryList from "./pages/categories/CategoryList";
import Tags from "./pages/tags/Tags";
import Features from "./pages/features/Features";
import Brands from "./pages/brand/Brands";
import PromotionsPage from "./component/promotions/Promotion";
import locale from "antd/es/locale/fa_IR";
import OrdersPage from "./pages/orders/Order";
import AdminSellerManagement from "./pages/sellers/AdminSellerManagement";
import ShopsIndex from "./pages/shops";
import AddShop from "./pages/shops/add";
import EditShop from "./pages/shops/edit";
import ProductIndex from "./pages/product/productList";
import CreateProduct from "./pages/product/addProduct";
import ProductForm from "./pages/product/addProduct";
import AddProduct from "./pages/product/addProduct";
import ProductList from "./pages/product/productList";
import BlogList from "./pages/blogs/BlogList";
import AddBlogPage from "./pages/blogs/AddBlog";
import EditBlogPage from "./pages/blogs/EditBlog";
import Immigration from "./pages/immigration-files/immigrationIndex";
import EditImmigrationFile from "./pages/immigration-files/EditImmigration";
import EvaluationList from "./pages/evaluation/evaluationIndex";
import Subscriptions from "./pages/subscriptions/subscriptions";
import Sample from "./pages/success samples/SampleList";
import ImmigrationPrograms from "./pages/plans/PlanList";
import AddImmigrationProgram from "./pages/plans/AddPlans";
import EditImmigrationProgram from "./pages/plans/EditPlans";
import SampleList from "./pages/success samples/SampleList";
import AddSamples from "./pages/success samples/AddSamples";
import EditSamples from "./pages/success samples/EditSamples";
import ConsultationList from "./pages/consultations/consultationsList";
import AddConsultations from "./pages/consultations/AddConsultations";
import TestimonialListl from "./pages/testimonial/testimonialList";
import AddTestimonial from "./pages/testimonial/AddTestimonial";
import EditTestimonial from "./pages/testimonial/EditTestimonial";
import TestimonialList from "./pages/testimonial/testimonialList";
import FaqIndex from "./pages/faq/faq plan/faqPlanList";
import FaqCreate from "./pages/faq/faq plan/AddFaqPlan";
import FaqEdit from "./pages/faq/faq plan/EditFaqPlan";
import FaqBlogList from "./pages/faq/faq blog/faqBlogList";
import AddFaqBlog from "./pages/faq/faq blog/AddFaqBlog";
import EditFaqBlog from "./pages/faq/faq blog/EditFaqBlog";
import Comments from "./pages/comment/Comment";
import AddBlog from "./pages/blogs/AddBlog";
import EditBlog from "./pages/blogs/EditBlog";
import CarreersList from "./pages/carreers/carreersList";
import AddCarreer from "./pages/carreers/AddCarreer";
import EditCarreer from "./pages/carreers/EditCarreer";
import Resume from "./pages/resume/resume";
import { useEffect } from "react";
import UsersPage from "./pages/users/UserManagement";
import PodcastList from "./pages/podcast/podcastList";
import PodcastAdd from "./pages/podcast/podcastAdd";
import PodcastEdit from "./pages/podcast/podcastEdit";


const theme = {
  token: {
    fontFamily: "IRANSans",
  },
  components: {
    Menu: {
      itemBorderRadius: 4,
    },
    Button: {
      borderRadius: 4,
    },
  },
};



function App() {
  const navigate = useNavigate()
  const params = useParams();

  useEffect(() =>{
    console.log(localStorage.getItem('token'))
    if(!localStorage.getItem('token')){

      navigate("/login")
    }
  },[])
  

  console.log(window.location.href)

  useEffect(() => {
    if (window.location.pathname === "/") { // Use === for comparison
      navigate("/dashboard");
    }
  }, [navigate]);


  return (
    <ConfigProvider direction="rtl" locale={locale} theme={theme}>
      <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />

            {/* تمام صفحات داخل MainLayout */}
            <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route
                path="/products/list"
                element={
                  <PrivateRoute
                  //  requiredPermissions={['view_products']}
                  >
                    <ProductList />
                  </PrivateRoute>
                }
              />
              <Route path="/products/create" element={<AddProduct />} />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/blogs/add" element={<AddBlog />} />
              <Route path="/blogs/edit/:id" element={<EditBlog />} />
              {/* <Route
                path="/products/categories"
                element={
                  <PrivateRoute>
                    <Categories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/tags"
                element={
                  <PrivateRoute>
                    <Tags />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/attributes"
                element={
                  <PrivateRoute>
                    <Attributes />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/brands"
                element={
                  <PrivateRoute>
                    <Brands />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/promotions"
                element={
                  <PrivateRoute>
                    <Promotions />
                  </PrivateRoute>
                }
              /> */}
              <Route path="/categories" element={<CategoryList />} />
              <Route path="/categories/add" element={<CategoryAdd />} />
              <Route path="/categories/edit/:id" element={<CategoryEdit />} />
              <Route
                path="/products/categories/tree"
                element={<CategoryTree />}
              />
              <Route path="/products/tags" element={<Tags />} />
              <Route path="/products/attributes" element={<Features />} />
              <Route path="/products/brands" element={<Brands />} />
              <Route path="/products/promotions" element={<PromotionsPage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route
                path="/roles"
                element={
                  <PrivateRoute
                  // requiredPermissions={["manage_roles"]}
                  >
                    <RoleManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute
                  //  requiredPermissions={["manage_users"]}
                  >
                    <UsersPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/immigration-files"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <Immigration />
                }
              />
              <Route
                path="/immigration-files/edit/:id"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <EditImmigrationFile />
                }
              />
              <Route
                path="/evaluation"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <EvaluationList />
                }
              />
              <Route
                path="/subscriptions"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <Subscriptions />
                }
              />
              <Route
                path="/success-samples"
                element={
                  //  requiredPermiss[ions={"view_orders"]}
                  <SampleList />
                }
              />
              <Route
                path="/success-samples/add"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <AddSamples />
                }
              />
              <Route
                path="/success-samples/edit/:id"
                element={
                  //  requiredPermissions={["view_orders"]}
                  <EditSamples />
                }
              />

              <Route path="/consultations" element={<ConsultationList />} />
              <Route path="/consultations/add" element={<AddConsultations />} />

              <Route path="/plans/" element={<ImmigrationPrograms />} />

              <Route path="/plans/add" element={<AddImmigrationProgram />} />

              <Route
                path="/plans/edit/:id"
                element={<EditImmigrationProgram />}
              />

              <Route path="/testimonial" element={<TestimonialList />} />

              <Route path="/testimonial/add" element={<AddTestimonial />} />

              <Route
                path="/testimonial/edit/:id"
                element={<EditTestimonial />}
              />

              <Route path="/faq/plan" element={<FaqIndex />} />
              <Route path="/faq/plan/add" element={<FaqCreate />} />
              <Route path="/faq/plan/edit/:id" element={<FaqEdit />} />

              <Route path="/faq/blog" element={<FaqBlogList />} />
              <Route path="/faq/blog/add" element={<AddFaqBlog />} />
              <Route path="/faq/blog/edit/:id" element={<EditFaqBlog />} />

              <Route path="/comments" element={<Comments />} />

              <Route path="/carreers" element={<CarreersList />} />
              <Route path="/carreers/add" element={<AddCarreer />} />
              <Route path="/carreers/edit/:id" element={<EditCarreer />} />


              <Route path="/resume" element={<Resume />} />


              <Route path="/podcast" element={<PodcastList />} />
              <Route path="/podcast/add" element={<PodcastAdd />} />
              <Route path="/podcast/edit/:id" element={<PodcastEdit />} />





              <Route
                path="/shops/list"
                element={
                  <PrivateRoute
                  //  requiredPermissions={["view_orders"]}
                  >
                    <ShopsIndex />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shops/add"
                element={
                  <PrivateRoute
                  //  requiredPermissions={["view_orders"]}
                  >
                    <AddShop />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shops/edit/:id"
                element={
                  <PrivateRoute
                  //  requiredPermissions={["view_orders"]}
                  >
                    <EditShop />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute
                  //  requiredPermissions={["manage_settings"]}
                  >
                    <div>صفحه تنظیمات</div>
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
