import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from "../../services/api"

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    
    // دریافت همه محصولات از API
    const fetchAllProducts = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/product/all`);
            setProducts(response.data.data);
        } catch (error) {
            message.error('خطا در دریافت اطلاعات');
        }
        setLoading(false);
    };

    // جستجوی محصولات از API سرچ
    const searchProducts = async (searchTerm) => {
        setLoading(true);
        try {
            const response = await api.get(`/product/search?search=${searchTerm}`);
            setProducts(response.data.data);
        } catch (error) {
            message.error('خطا در جستجوی محصولات');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAllProducts();
    }, []);

    // هندل تغییرات در فیلد جستجو
    const handleSearch = (value) => {
        setSearchText(value);
        if (value) {
            searchProducts(value);
        } else {
            fetchAllProducts();
        }
    };

    // حذف محصول
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'آیا از حذف این محصول اطمینان دارید؟',
            okText: 'بله',
            cancelText: 'خیر',
            onOk: async () => {
                try {
                    await axios.delete(`/api/products/${id}`);
                    message.success('محصول با موفقیت حذف شد');
                    if (searchText) {
                        searchProducts(searchText);
                    } else {
                        fetchAllProducts();
                    }
                } catch (error) {
                    message.error('خطا در حذف محصول');
                }
            }
        });
    };

    // تعریف ستون‌های جدول
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 70,
        },
        {
          title: 'نام محصول',
          dataIndex: 'title',
          key: 'title',
      },
        {
            title: 'تصویر محصول',
            dataIndex: 'image',
            key: 'image',
            render: (image) => (
                <img 
                    src={image} 
                    alt="تصویر محصول" 
                    style={{ 
                        width: '100px', 
                        height: '100px', 
                        objectFit: 'cover' 
                    }} 
                />
            ),
        },
        
        {
            title: 'قیمت',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'موجودی',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => window.location.href = `/products/edit/${record.id}`}
                    >
                        ویرایش
                    </Button>
                    <Button 
                        type="primary" 
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record.id)}
                    >
                        حذف
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                <Input
                    placeholder="جستجوی محصول..."
                    allowClear
                    size="large"
                    style={{ width: 300 }}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={() => window.location.href = '/products/create'}
                >
                    افزودن محصول جدید
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={products}
                loading={loading}
                rowKey="id"
                pagination={{
                    total: products.length,
                    pageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `مجموع ${total} محصول`
                }}
            />
        </div>
    );
};

export default ProductList;