import React, { useState, useEffect } from "react";
import { Table, Button, Space, Modal, message, Divider, Input, DatePicker } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PrinterOutlined,
  FileTextOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import logo from "../../images/Mask group (6).png";
import dayjs from 'dayjs';


const printStyles = `
  @media print {
    body * {
      visibility: hidden;
    }
    #print-content, #print-content * {
      visibility: visible;
    }
    #print-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .ant-modal-footer,
    .ant-modal-close {
      display: none !important;
    }
  }
`;

const EvaluationList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [meetingForm, setMeetingForm] = useState({
    title: '',
    body: '',
    admin_note: '',
    date: null,
  });

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=Evaluation&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: '',
      body: '',
      admin_note: '',
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format("YYYY-MM-DD HH:mm:ss");
      
      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        evaluation_id: selectedMeetingFileId,
      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: '',
        body: '',
        admin_note: '',
        date: null,
      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };

  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=Evaluation&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };

  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        evaluation_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };

  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  ////////////////////

  const fetchEvaluation = async () => {
    setLoading(true);
    try {
      const response = await api.get("/panel/evaluation");
      const transformedData = response.data.data.map((item) => ({
        id: item.id,
        email: item.email,
        created_at: item.created_at,
        plan: item.plan,
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.mobile,
        age: item.age,
        gender: item.gender,
        marriage: item.marriage,
        status: item.status,
      }));
      setRequests(transformedData);
    } catch (error) {
      message.error("خطا در دریافت اطلاعات");
    }
    setLoading(false);
  };

  const fetchUserDetails = async (id) => {
    try {
      const response = await api.get(`/panel/evaluation/${id}`);
      setSelectedUser(response.data.data);
      setModalVisible(true);
    } catch (error) {
      message.error("خطا در دریافت جزئیات کاربر");
    }
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    fetchEvaluation();
  }, []);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "آیا از حذف این درخواست اطمینان دارید؟",
      okText: "بله",
      cancelText: "خیر",
      onOk: async () => {
        try {
          await api.delete(`/panel/evaluation/${id}`);
          message.success("درخواست با موفقیت حذف شد");
          fetchEvaluation();
        } catch (error) {
          message.error("خطا در حذف درخواست");
        }
      },
    });
  };

  const columns = [
    {
      title: "نام",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "نام خانوادگی",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "ایمیل کاربر",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "تاریخ",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "طرح",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => fetchUserDetails(record.id)}
          >
            مشاهده
          </Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          >
            حذف
          </Button>
          <Button
          className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          >
            یادداشت
          </Button>
          <Button
          className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          >
            جلسه
          </Button>
        </Space>
      ),
    },
  ];

  const renderUserDetails = () => {
    if (!selectedUser) return null;

    return (
      <div id="print-content" style={{ direction: "rtl" }}>
        <style>{printStyles}</style>
        <img
          src={logo}
          alt="Logo"
          className={`mx-auto mb-4  transition-all duration-300`}
        />
        <h3 className="mt-5 text-lg block px-2 text-center bg-red-400">
          اطلاعات شخصی
        </h3>
        <div className="flex flex-col mt-4">
          <div className="flex justify-between">
            <div className="w-1/3">
              <p>نام: {selectedUser.first_name}</p>
            </div>
            <div className="w-1/3">
              <p>نام خانوادگی: {selectedUser.last_name}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="w-1/3">
                <p>ایمیل: {selectedUser.email}</p>
              </div>
              <div className="w-1/3">
                <p>موبایل: {selectedUser.mobile}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
          <div className="flex justify-between">
          <div className="w-1/3">
          <p>سن: {selectedUser.age}</p>
          </div>
          <div className="w-1/3">
          <p>جنسیت: {selectedUser.gender}</p>
          </div>
          </div>
          </div>
          <p>وضعیت تاهل: {selectedUser.marriage}</p>
        </div>

        <h3 className="mt-5 text-lg block px-2 text-center  bg-red-400">
          سوابق تحصیلی{" "}
        </h3>
        {selectedUser.language_level && (
          <>
          <div className="mt-4 flex flex-col">
          <div className="flex justify-between items-center">
            <div className="w-1/3">
            <p>آخرین مدرک تحصیلی: {selectedUser.education.degree}</p>
            </div>
            <div className="w-1/3">
            <p>
              مدرک انگلیسی: {selectedUser.language_level.english_certificate}
            </p>
            </div>
            </div>
            </div>
            <div className="flex justify-between items-center">
            <div className="w-1/3">
            <p>
              نوع مدرک: {selectedUser.language_level.english_certificate_type}
            </p>
            </div>
            <div className="w-1/3">
            <p>سطح انگلیسی: {selectedUser.language_level.english_level}</p>
            </div>
            </div>
            <p>مدرک فرانسه: {selectedUser.language_level.french_certificate}</p>
          </>
        )}

        <h3 className="mt-5 text-lg block px-2 text-center  bg-red-400">
          {" "}
          اطلاعات بیشتر
        </h3>
        {selectedUser.work_experience && (
          <p>سابقه کار: {selectedUser.work_experience.work_ex_history}</p>
        )}
      </div>
    );
  };

  return (
    <div style={{ padding: 24 }}>
      <h1 className="mb-10 text-xl">فرم های ارزیابی</h1>
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        scroll={{
          x: 1000, // Set the horizontal scroll width
        }}
        rowKey="id"
        pagination={{
          total: requests.length,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `مجموع ${total} درخواست`,
        }}
      />

      <Modal
        title="جزئیات کاربر"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={800}
        footer={[
          <Button
            key="print"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={handlePrint}
          >
            پرینت
          </Button>,
          <Button key="close" onClick={() => setModalVisible(false)}>
            بستن
          </Button>,
        ]}
      >
        {renderUserDetails()}
      </Modal>
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) => setMeetingForm({ ...meetingForm, title: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) => setMeetingForm({ ...meetingForm, body: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) => setMeetingForm({ ...meetingForm, admin_note: e.target.value })}
            />
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={!meetingForm.title || !meetingForm.date}
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};

export default EvaluationList;
