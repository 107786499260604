// pages/shops/edit.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  Button,
  Space,
  message,
  Typography,
  Spin,
} from 'antd';
import {
  UploadOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  SaveOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const EditShop = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [videoFile, setVideoFile] = useState(null);

  // Simulating API call to get shop data
  useEffect(() => {
    // در اینجا باید API call برای دریافت اطلاعات فروشگاه انجام شود
    const fetchShopData = async () => {
      try {
        // Simulated API response
        const shopData = {
          title: 'فروشگاه نمونه',
          description: 'توضیحات نمونه',
          phone: '09123456789',
          tags: ['پوشاک', 'مد'],
          type: 'retail',
          address: 'تهران، خیابان ...',
          commission: 10,
          tax: 9,
          logo: [{
            uid: '-1',
            name: 'logo.png',
            status: 'done',
            url: 'https://example.com/logo.png',
          }],
          background: [{
            uid: '-1',
            name: 'background.png',
            status: 'done',
            url: 'https://example.com/background.png',
          }],
          gallery: [
            {
              uid: '-1',
              name: 'image1.png',
              status: 'done',
              url: 'https://example.com/image1.png',
            },
            {
              uid: '-2',
              name: 'image2.png',
              status: 'done',
              url: 'https://example.com/image2.png',
            }
          ],
          video: [{
            uid: '-1',
            name: 'video.mp4',
            status: 'done',
            url: 'https://example.com/video.mp4',
          }]
        };

        form.setFieldsValue(shopData);
        setGalleryImages(shopData.gallery);
        setVideoFile(shopData.video[0]);
        setLoading(false);
      } catch (error) {
        message.error('خطا در دریافت اطلاعات فروشگاه');
        navigate('/shops');
      }
    };

    fetchShopData();
  }, [id, form, navigate]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // در اینجا باید API call برای به‌روزرسانی اطلاعات فروشگاه انجام شود
      console.log('Updated values:', values);
      message.success('فروشگاه با موفقیت به‌روزرسانی شد');
      navigate('/shops');
    } catch (error) {
      message.error('خطا در به‌روزرسانی اطلاعات فروشگاه');
    } finally {
      setLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  if (loading) {
    return (
      <Card style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>در حال بارگذاری اطلاعات...</p>
      </Card>
    );
  }

  return (
    <Card>
      <Space direction="horizontal" style={{ marginBottom: 16 }}>
        <Button 
          icon={<ArrowRightOutlined />} 
          onClick={() => navigate('/shops')}
        >
          بازگشت
        </Button>
        <Title level={4}>ویرایش فروشگاه</Title>
      </Space>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Card title="تصاویر اصلی" style={{ marginBottom: 24 }}>
          <Form.Item
            name="logo"
            label="لوگو"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>تغییر لوگو</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            name="background"
            label="تصویر پس‌زمینه"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>تغییر تصویر پس‌زمینه</div>
              </div>
            </Upload>
          </Form.Item>
        </Card>

        <Card title="اطلاعات کلی" style={{ marginBottom: 24 }}>
          <Form.Item
            name="title"
            label="نام فروشگاه"
            rules={[{ required: true, message: 'لطفا نام فروشگاه را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات"
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="تلفن"
            rules={[{ required: true, message: 'لطفا تلفن را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tags"
            label="تگ‌ها"
          >
            <Select mode="tags" style={{ width: '100%' }} placeholder="تگ‌ها را وارد کنید">
            </Select>
          </Form.Item>

          <Form.Item
            name="type"
            label="نوع فروشگاه"
            rules={[{ required: true, message: 'لطفا نوع فروشگاه را انتخاب کنید' }]}
          >
            <Select>
              <Option value="retail">خرده‌فروشی</Option>
              <Option value="wholesale">عمده‌فروشی</Option>
              <Option value="service">خدماتی</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="address"
            label="آدرس"
            rules={[{ required: true, message: 'لطفا آدرس را وارد کنید' }]}
          >
            <TextArea rows={3} />
          </Form.Item>
        </Card>

        <Card title="اطلاعات سفارش" style={{ marginBottom: 24 }}>
          <Form.Item
            name="commission"
            label="کمیسیون ادمین (درصد)"
            rules={[{ required: true, message: 'لطفا درصد کمیسیون را وارد کنید' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="tax"
            label="مالیات (درصد)"
            rules={[{ required: true, message: 'لطفا درصد مالیات را وارد کنید' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Card>

        <Card title="گالری و ویدیو" style={{ marginBottom: 24 }}>
          <Form.Item
            name="gallery"
            label="تصاویر گالری"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              multiple
              beforeUpload={() => false}
              onChange={({ fileList }) => setGalleryImages(fileList)}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>افزودن تصویر</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            name="video"
            label="ویدیو"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={({ fileList }) => setVideoFile(fileList[0])}
            >
              <Button icon={<VideoCameraOutlined />}>تغییر ویدیو</Button>
            </Upload>
          </Form.Item>
        </Card>

        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              size="large"
              loading={loading}
            >
              به‌روزرسانی فروشگاه
            </Button>
            <Button 
              onClick={() => navigate('/shops')}
              size="large"
            >
              انصراف
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default EditShop;