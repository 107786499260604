// components/products/steps/GeneralInfo.js
import React from 'react';
import { Col, Form, Input, Row, Select, Switch } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const GeneralInfo = () => {
  const [form] = Form.useForm();

 const onFinish = () =>{
    
 }


  return (
    <Form
      form={form}
      layout="vertical"
    //   initialValues={}
    //   onFinish={onValuesChange}
    >
      <Form.Item
        name="title"
        label="نام محصول"
        rules={[{ required: true, message: 'نام محصول الزامی است' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="توضیحات"
        rules={[{ required: true, message: 'توضیحات الزامی است' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Row>
        <Col>
      <Form.Item
        name="category"
        label="دسته‌بندی"
        rules={[{ required: true, message: 'دسته‌بندی الزامی است' }]}
      >
        <Select>
          <Option value="category1">دسته 1</Option>
          <Option value="category2">دسته 2</Option>
        </Select>
      </Form.Item>
      </Col>

      </Row>

      <Form.Item
        name="isActive"
        label="وضعیت نمایش"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default GeneralInfo;