import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Select, Button, message, Spin, Upload } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { UploadOutlined } from "@ant-design/icons";
import api from "../../services/api";
import TextArea from "antd/es/input/TextArea";
import TinyEditor from "../../component/editor/Editor";

const EDITOR_CONFIG = {
  height: 500,
  menubar: true,
  branding: false,
  statusbar: false,
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "media",
    "table",
    "help",
    "wordcount",
    "directionality",
  ],
  toolbar:
    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help | rtl ltr | image",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  directionality: "rtl",
  language: "fa",
};

const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [charCount, setCharCount] = useState(0); 
  const maxCharLimit = 500; 

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);

  };


 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, blogResponse] = await Promise.all([
          api.get("/panel/category"),
          api.get(`/panel/blog/${id}`),
        ]);

        const blogData = blogResponse.data.data;
        setCategories(categoriesResponse.data.data);

        if (blogData.thumb) {
          setFileList([
            {
              uid: "-1",
              name: "current-image.png",
              status: "done",
              url: blogData.thumb,
            },
          ]);
        }

        form.setFieldsValue({
          title: blogData.title,
          description: blogData.description,
          category_id: blogData?.category?.id ,
        });

        setEditorContent(blogData.body);
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, [id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Add text fields to FormData
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("categories[]", values.category_id);
      formData.append("body", editorContent);

      // Add image file if exists
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("thumb", fileList[0].originFileObj);
      }

      await api.post(`/panel/blog/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("بلاگ با موفقیت ویرایش شد");
      navigate("/blogs");
    } catch (error) {
      message.error("خطا در ویرایش بلاگ");
    }
    setLoading(false);
  };

  const uploadProps = {
    fileList: fileList,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("فقط می‌توانید تصویر آپلود کنید!");
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
  };

  if (initialLoading) {
    return <Spin size="large" />;
  }

  return (
    <div className="p-6">
      <h2 className="mb-10 text-xl">ویرایش بلاگ</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[{ required: true, message: "لطفا عنوان را وارد کنید" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[{ required: true, message: "لطفا توضیحات را وارد کنید" }]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            name="category_id"
            label="دسته‌بندی"
            // rules={[{ required: true, message: 'لطفا دسته‌بندی را انتخاب کنید' }]}
          >
            <Select>
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="thumb" label="بنر">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>انتخاب تصویر</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="محتوای اصلی"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  editorContent
                    ? Promise.resolve()
                    : Promise.reject("لطفا محتوا را وارد کنید"),
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"Blog"}
              height={1000}
            />. <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
            {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
            {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
          </div>
          </Form.Item>

          <Form.Item className="flex gap-2">
            <Button type="primary" htmlType="submit">
              ذخیره تغییرات
            </Button>
            <Button onClick={() => navigate("/blogs")}>انصراف</Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditBlog;
