import { useEffect, useMemo, useState } from "react";
import { Layout, Menu, Switch, Button, theme, Badge } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import logo from "../../images/Mask group (6).png";
import {
  DashboardOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  SettingOutlined,
  TeamOutlined,
  LogoutOutlined,
  BulbOutlined,
  BarcodeOutlined,
  AppstoreOutlined,
  TagsOutlined,
  FileTextOutlined,
  StarOutlined,
  GiftOutlined,
  FormOutlined,
  ReadOutlined,
  SolutionOutlined,
  AppstoreAddOutlined,
  MessageOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  CalendarOutlined,
  ProfileOutlined,
  BellOutlined,
  CheckCircleOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import api from "../../services/api";

const { Header, Sider, Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { token } = theme.useToken();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);
  const [notificationCounts, setNotificationCounts] = useState({});

  const fetchUserData = async (token) => {
    try {
      const response = await api.get("/panel/user/info");
      let arr = [];
      // setUser(response.data.data.meta.token);
      if (response?.data?.data?.permissions) {
        response?.data?.data?.permissions?.map((item) => {
          arr.push(item?.name);
        });
        setPermissions(arr);
      }

      console.log("permission", permissions);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // logout();
    } finally {
      setLoading(false);
    }
  };

  const fetchNotification = async () => {
    try {
      const response = await api.get("/panel/new-items-count");
      setNotificationCounts(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching notification counts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchNotification();
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const menuItems = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: "داشبورد",
      onClick: () => navigate("/dashboard"),
      permission: null,
    },
    // {
    //     key: 'products',
    //     icon: <ShopOutlined />,
    //     label: 'محصولات',
    //     children: [
    //       {
    //         key: 'products-list',
    //         icon: <BarcodeOutlined />,
    //         label: 'لیست محصولات',
    //         onClick: () => navigate('/products/list')
    //       },

    //       {
    //         key: 'tags',
    //         icon: <TagsOutlined />,
    //         label: 'برچسب‌ها',
    //         onClick: () => navigate('/products/tags')
    //       },
    //       {
    //         key: 'attributes',
    //         icon: <FileTextOutlined />,
    //         label: 'ویژگی‌ها',
    //         onClick: () => navigate('/products/attributes')
    //       },
    //       {
    //         key: 'brands',
    //         icon: <StarOutlined />,
    //         label: 'برندها',
    //         onClick: () => navigate('/products/brands')
    //       },
    //       {
    //         key: 'promotions',
    //         icon: <GiftOutlined />,
    //         label: 'تخفیف‌ها و پروموشن‌ها',
    //         onClick: () => navigate('/products/promotions')
    //       }
    //     ]
    //   },
    
    

    {
      key: "customer-relations",
      icon: <TeamOutlined />,
      label: "ارتباط با مشتری",
      children: [
        {
          key: "immigration-files",
          icon: <FileTextOutlined />,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              پرونده‌های مهاجرتی
              <Badge count={notificationCounts.new_immigration_file_count || 0} />
            </div>
          ),
          onClick: () => navigate("/immigration-files"),
    
          permission: null,
        },
        
        {
          key: "resume",
          icon: <ProfileOutlined />,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Badge count={notificationCounts.new_resume_count} offset={[40, 40]}>
                رزومه‌های ارسالی
              </Badge>
            </div>
          ),
          onClick: () => navigate("/resume"),
          permission: "resume",
        },
        
        {
          key: "comments",
          icon: <MessageOutlined />,
          label: "نظرات",
          onClick: () => navigate("/comments"),
          permission: "comment",
        },
        {
          key: "evaluation",
          icon: <StarOutlined />,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Badge
                count={notificationCounts.new_evaluation_count}
                offset={[10, 0]}
              >
                فرم‌های ارزیابی
              </Badge>
            </div>
          ),
          onClick: () => navigate("/evaluation"),
          permission: "evaluation",
        },
        {
          key: "subscriptions",
          icon: <BellOutlined />,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Badge
                count={notificationCounts.new_subscriber_count}
                offset={[10, 0]}
              >
                خبرنامه
              </Badge>
            </div>
          ),
          onClick: () => navigate("/subscriptions"),
          permission: "subscription",
        },
        {
          key: "consultations",
          icon: <PhoneOutlined />,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Badge
                count={notificationCounts.new_consultation_count}
                offset={[10, 0]}
              >
                درخواست‌های مشاوره
              </Badge>
            </div>
          ),
          onClick: () => navigate("/consultations"),
          permission: "consultation",
        },
      ],
    },

    {
      key: "plans",
      icon: <CalendarOutlined />,
      label: "  برنامه‌های مهاجرتی ",
      onClick: () => navigate("/plans"),
      permission: "plan",
    },

    {
      key: "categories",
      icon: <AppstoreAddOutlined />,
      label: "دسته‌بندی‌ها",
      onClick: () => navigate("/categories"),
      permission: "category",
    },
    
    {
      key: "testimonial",
      icon: <UserOutlined />,
      label: " نظرات برتر",
      onClick: () => navigate("/testimonial"),
      permission: "testimonial",
    },
    {
      key: "blogs",
      icon: <ReadOutlined />,
      label: "بلاگ‌ها",
      onClick: () => navigate("/blogs"),
      permission: "blog",
    },
    {
      key: "faq",
      icon: <QuestionCircleOutlined />,
      label: "سوالات متداول",
      permission: "faq",

      // onClick: () => navigate('/sellers')
      children: [
        {
          key: "faq/plan",
          icon: <QuestionCircleOutlined />,
          label: "سوالات متداول برنامه‌ها ",
          onClick: () => navigate("/faq/plan"),
        },
        {
          key: "faq/blog",
          icon: <QuestionCircleOutlined />,
          label: " سوالات متداول بلاگ",
          onClick: () => navigate("/faq/blog"),
        },
      ],
    },
    
    
   
    {
      key: "success-samples",
      icon: <CheckCircleOutlined />,
      label: " نمونه‌های موفق ",
      onClick: () => navigate("/success-samples"),
      permission: "successful-example",
    },
    {
      key: "carreers",
      icon: <SolutionOutlined />,
      label: "فرصت‌های شغلی",
      onClick: () => navigate("/carreers"),
      permission: null,
    },

    {
      key: "podcast",
      icon: <SoundOutlined />,
      label: "  پادکست ",
      onClick: () => navigate("/podcast"),
      permission: null,
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: "مدیریت کاربران",
      onClick: () => navigate("/users"),
      permission: "users",
    },
    {
      key: "roles",
      icon: <TeamOutlined />,
      label: "مدیریت نقش‌ها",
      onClick: () => navigate("/roles"),
      permission: "roles",
    },

    {
      type: "divider",
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "خروج",
      onClick: handleLogout,
      permission: null,
      className: "text-red-500",
      danger: true,
    },
  ];

  const filteredMenuItems = useMemo(() => {
    return menuItems.filter(
      (item) => !item.permission || permissions.includes(item.permission)
    );
  }, [permissions]);

  return (
    <Layout className="min-h-screen">
      <Sider
        className="border-r border-gray-200 shadow-sm"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        theme={isDarkMode ? "dark" : "light"}
        width={250} // عرض پیش‌فرض سایدبار
        collapsedWidth={80} // عرض در حالت جمع شده
      >
        <div
          className={`p-4 ${
            isDarkMode ? "text-white" : "text-black"
          }  border-gray-200`}
        >
          <img
            src={logo}
            alt="Logo"
            className={`mx-auto mb-2 ${
              collapsed ? "w-10" : "w-24"
            } transition-all duration-300`}
          />

          {/* <h1 className={`mx-auto mb-2 text-center ${collapsed ? 'hidden' : 'text-xl'} transition-all duration-300`}> {user.name}</h1> */}
        </div>
        <Menu
          theme={isDarkMode ? "dark" : "light"}
          mode="inline"
          items={filteredMenuItems}
          defaultSelectedKeys={[location.pathname.substring(1)]} // Dynamically set the selected key
          defaultOpenKeys={[location.pathname.substring(1)]}
          className="border-r-0"
        />
      </Sider>
      <Layout>
        <Header
          className={`
            ${isDarkMode ? "bg-[#001529]" : "bg-white"} 
            px-4 
            flex 
            justify-end 
            items-center 
            shadow-sm
            border-b 
            border-gray-200
            h-16
          `}
        >
          <div className="flex items-center gap-4">
            <Switch
              checked={isDarkMode}
              onChange={setIsDarkMode}
              checkedChildren={<BulbOutlined />}
              unCheckedChildren={<BulbOutlined />}
            />
          </div>
        </Header>
        <Content className="m-4">
          <div
            className={`
            p-6 
            rounded-lg 
            ${isDarkMode ? "bg-[#1f1f1f] text-white" : "bg-white"} 
            shadow-sm 
            border 
            border-gray-200
          `}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
