import React, { useState } from 'react';
import { Card, Tabs, Form, Input, InputNumber, Upload, Button, Select } from 'antd';
import { 
  InfoCircleOutlined, 
  FileImageOutlined, 
  DollarOutlined, 
  GlobalOutlined,
  UploadOutlined 
} from '@ant-design/icons';
import GeneralInfo from './steps/GeneralInfo';
import FilesMedia from './steps/FilesMedia';
import PriceStock from './steps/PriceStock';
import SeoInfo from './steps/SeoInfo';

const { TabPane } = Tabs;

const ProductForm = () => {
  const [form] = Form.useForm();
  
  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  

  return (
    <Card title="افزودن محصول">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <InfoCircleOutlined />
                اطلاعات کلی
              </span>
            }
            key="1"
          >
            <GeneralInfo />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FileImageOutlined />
                فایل‌ها و رسانه
              </span>
            }
            key="2"
          >
            <FilesMedia />
          </TabPane>

          <TabPane
            tab={
              <span>
                <DollarOutlined />
                قیمت و موجودی
              </span>
            }
            key="3"
          >
            <PriceStock />
          </TabPane>

          <TabPane
            tab={
              <span>
                <GlobalOutlined />
                سئو
              </span>
            }
            key="4"
          >
            <SeoInfo />
          </TabPane>
        </Tabs>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            ذخیره محصول
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ProductForm;