import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Space,
  Input,
  Form,
  Modal,
  Tag,
  Popconfirm,
  message,
  Upload,
  Image,
  Switch,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import api from "../../services/api";


const Brands = () => {
  const [form] = Form.useForm();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingBrand, setEditingBrand] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const fetchBrands = async() => {
    setLoading(true);
    try {
      const response = await api.get("/brand/all");

      setBrands(response.data.data);
      setLoading(false);
    } catch (error) {
      message.error("خطا در دریافت برندها");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // آپلود تصویر
  const handleImageUpload = async (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error('فقط فرمت‌های PNG و JPEG پشتیبانی می‌شوند');
      return false;
    }
    
    if (file.size > 2 * 1024 * 1024) {
      message.error('حجم تصویر نباید بیشتر از 2 مگابایت باشد');
      return false;
    }
  
    try {
      const formData = new FormData();
      formData.append('image', file);
  
      const response = await api.post('/brand/upload-image', formData);
      
      if (response.status === 200) {
        setImageUrl(response.data.imageUrl);
      }
      return false; // جلوگیری از آپلود خودکار
    } catch (error) {
      message.error('خطا در آپلود تصویر');
      console.error(error);
      return false;
    }
  };

  // افزودن برند جدید
  const handleAdd = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('slug', values.slug);
      formData.append('description', values.description);
      formData.append('website', values.website);
      formData.append('status', values.status);
      formData.append('logo', imageUrl);
  
      const response = await api.post('/brand/create', formData);
      
      if (response.status === 201) {
        message.success('برند با موفقیت اضافه شد');
        fetchBrands();  
        setModalVisible(false);
        setImageUrl('');
        form.resetFields();
      }
    } catch (error) {
      message.error('خطا در افزودن برند');
      console.error(error);
    }
  };

  // ویرایش برند
  const handleEdit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('slug', values.slug);
      formData.append('description', values.description);
      formData.append('website', values.website);
      formData.append('status', values.status);
      if (imageUrl !== editingBrand.logo) {
        formData.append('logo', imageUrl);
      }
  
      const response = await api.put(`/brand/update/${editingBrand.id}`, formData);
  
      if (response.status === 200) {
        message.success('برند با موفقیت ویرایش شد');
        fetchBrands();
        setModalVisible(false);
        setEditingBrand(null);
        setImageUrl('');
        form.resetFields();
      }
    } catch (error) {
      message.error('خطا در ویرایش برند');
      console.error(error);
    }
  };


  // حذف برند
const handleDelete = async (id) => {
  try {
    const response = await api.delete(`/brand/delete/${id}`);
    
    if (response.status === 200) {
      message.success('برند با موفقیت حذف شد');
      fetchBrands();
    }
  } catch (error) {
    message.error('خطا در حذف برند');
    console.error(error);
  }
};

  // تغییر وضعیت برند
  const handleStatusChange = async (id, checked) => {
  try {
    const response = await api.patch(`/brand/status/${id}`, {
      status: checked ? 'active' : 'inactive'
    });

    if (response.status === 200) {
      message.success('وضعیت برند با موفقیت تغییر کرد');
      fetchBrands();
    }
  } catch (error) {
    message.error('خطا در تغییر وضعیت برند');
    console.error(error);
  }
};



  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: "لوگو",
      dataIndex: "logo",
      key: "logo",
      width: 100,
      render: (logo) => (
        <Image
          src={logo}
          alt="لوگو برند"
          width={50}
          height={50}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "نام برند",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()) ||
        record.englishName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "پیوند ",
      dataIndex: "slug",
      key: "slug",
    },
    // {
    //   title: "توضیحات",
    //   dataIndex: "description",
    //   key: "description",
    //   ellipsis: true,
    // },
    // {
    //   title: "وب‌سایت",
    //   dataIndex: "website",
    //   key: "website",
    //   render: (website) => (
    //     <a href={website} target="_blank" rel="noopener noreferrer">
    //       {website}
    //     </a>
    //   ),
    // },
    {
      title: "وضعیت",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status, record) => (
        <Switch
          checked={status === "active"}
          onChange={(checked) => handleStatusChange(record.id, checked)}
          checkedChildren="فعال"
          unCheckedChildren="غیرفعال"
        />
      ),
    },
    {
      title: "عملیات",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingBrand(record);
              setImageUrl(record.logo);
              form.setFieldsValue(record);
              setModalVisible(true);
            }}
          >
            ویرایش
          </Button>
          <Popconfirm
            title="آیا از حذف این برند اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
            icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              حذف
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title="مدیریت برندها"
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingBrand(null);
            setImageUrl("");
            form.resetFields();
            setModalVisible(true);
          }}
        >
          افزودن برند
        </Button>
      }
    >
      <Input.Search
        placeholder="جستجو در برندها"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16 }}
      />

      <Table
        columns={columns}
        dataSource={brands}
        loading={loading}
        rowKey="id"
      />
      
      <Modal
        title={editingBrand ? "ویرایش برند" : "افزودن برند جدید"}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingBrand(null);
          setImageUrl("");
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={editingBrand ? handleEdit : handleAdd}
        >
          <Form.Item
            name="name"
            label="نام برند"
            rules={[{ required: true, message: "لطفاً نام برند را وارد کنید" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="slug"
            label="پیوند "
            rules={[
              {
                required: true,
                message: "لطفاً نام انگلیسی برند را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="توضیحات">
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="website"
            label="وب‌سایت"
            rules={[{ type: "url", message: "فرمت آدرس وب‌سایت صحیح نیست" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="لوگو">
            <Upload
              accept="image/png,image/jpeg"
              showUploadList={false}
              beforeUpload={handleImageUpload}
            >
              <Button icon={<UploadOutlined />}>انتخاب تصویر</Button>
            </Upload>
            {imageUrl && (
              <div style={{ marginTop: 8 }}>
                <Image
                  src={imageUrl}
                  alt="لوگو"
                  style={{ maxWidth: "200px" }}
                />
              </div>
            )}
          </Form.Item>

          <Form.Item name="status" label="وضعیت" initialValue="active">
            <Switch
              defaultChecked
              checkedChildren="فعال"
              unCheckedChildren="غیرفعال"
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingBrand ? "ویرایش" : "افزودن"}
              </Button>
              <Button
                onClick={() => {
                  setModalVisible(false);
                  setEditingBrand(null);
                  setImageUrl("");
                  form.resetFields();
                }}
              >
                انصراف
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default Brands;
