import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Button,
  Card,
  message,
  Space,
  Table,
  ConfigProvider
} from 'antd';
import moment from 'moment-jalaali';
import fa_IR from 'antd/lib/locale/fa_IR';
import locale from 'antd/es/locale/fa_IR';

// تنظیمات ضروری moment-jalaali
moment.loadPersian({
  usePersianDigits: true,
  dialect: 'persian-modern'
});

const { RangePicker } = DatePicker;
const { Option } = Select;

// کامپوننت RangePicker سفارشی شده
const JalaliRangePicker = ({ value, onChange, format = 'jYYYY/jMM/jDD', ...props }) => {
  return (
    <RangePicker
      {...props}
      calendar={moment}
      locale={fa_IR}
      format={format}
      onChange={onChange}
      value={value}
    />
  );
};

const Promotion = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async () => {
    try {
      setLoading(true);
      // اینجا کد دریافت لیست تخفیف‌ها از API را قرار دهید
      // const response = await axios.get('/api/promotions');
      // setPromotions(response.data);
    } catch (error) {
      message.error('خطا در دریافت لیست تخفیف‌ها');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = {
        ...values,
        startDate: values.dateRange[0].format('jYYYY/jMM/jDD'),
        endDate: values.dateRange[1].format('jYYYY/jMM/jDD'),
      };
      // اینجا کد ارسال اطلاعات به API را قرار دهید
      // await axios.post('/api/promotions', formData);
      message.success('تخفیف با موفقیت ایجاد شد');
      form.resetFields();
      fetchPromotions();
    } catch (error) {
      message.error('خطا در ایجاد تخفیف');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePromotion = async (id) => {
    try {
      setLoading(true);
      // اینجا کد حذف تخفیف از API را قرار دهید
      // await axios.delete(`/api/promotions/${id}`);
      message.success('تخفیف با موفقیت حذف شد');
      fetchPromotions();
    } catch (error) {
      message.error('خطا در حذف تخفیف');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'عنوان تخفیف',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'کد تخفیف',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'نوع تخفیف',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type === 'percentage' ? 'درصدی' : 'مبلغی',
    },
    {
      title: 'مقدار تخفیف',
      dataIndex: 'value',
      key: 'value',
      render: (value, record) => record.type === 'percentage' ? `${value}%` : `${value.toLocaleString()} تومان`,
    },
    {
      title: 'تاریخ شروع',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'تاریخ پایان',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'وضعیت',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        <Switch checked={isActive} disabled />
      ),
    },
    {
      title: 'عملیات',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleEdit(record)}>ویرایش</Button>
          <Button type="primary" danger onClick={() => deletePromotion(record.id)}>حذف</Button>
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    form.setFieldsValue({
      ...record,
      dateRange: [
        moment(record.startDate, 'jYYYY/jMM/jDD'),
        moment(record.endDate, 'jYYYY/jMM/jDD'),
      ],
    });
  };

  return (
    <ConfigProvider locale={locale} direction="rtl">
      <div style={{ padding: '20px' }}>
        <Card title="ایجاد تخفیف جدید" style={{ marginBottom: '20px' }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '16px' }}>
              <Form.Item
                name="title"
                label="عنوان تخفیف"
                rules={[{ required: true, message: 'لطفا عنوان تخفیف را وارد کنید' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="code"
                label="کد تخفیف"
                rules={[{ required: true, message: 'لطفا کد تخفیف را وارد کنید' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="type"
                label="نوع تخفیف"
                rules={[{ required: true, message: 'لطفا نوع تخفیف را انتخاب کنید' }]}
              >
                <Select>
                  <Option value="percentage">درصدی</Option>
                  <Option value="fixed">مبلغی</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="value"
                label="مقدار تخفیف"
                rules={[{ required: true, message: 'لطفا مقدار تخفیف را وارد کنید' }]}
              >
                <InputNumber 
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>

              <Form.Item
                name="minPurchase"
                label="حداقل مبلغ خرید"
              >
                <InputNumber 
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>

              <Form.Item
                name="maxUsage"
                label="حداکثر دفعات استفاده"
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>

              <Form.Item
                name="dateRange"
                label="بازه زمانی اعتبار"
                rules={[{ required: true, message: 'لطفا بازه زمانی را انتخاب کنید' }]}
              >
                <JalaliRangePicker 
                  style={{ width: '100%' }}
                  format="jYYYY/jMM/jDD"
                />
              </Form.Item>

              <Form.Item
                name="userLimit"
                label="محدودیت استفاده برای هر کاربر"
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>

              <Form.Item
                name="isActive"
                label="فعال"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                ایجاد تخفیف
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Card title="لیست تخفیف‌ها">
          <Table
            columns={columns}
            dataSource={promotions}
            loading={loading}
            rowKey="id"
            pagination={{
              total: promotions.length,
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `مجموع: ${total} مورد`,
            }}
          />
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default Promotion;