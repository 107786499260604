// ResumeTable.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, message, Space, DatePicker, Input, Modal, Tag, Select } from 'antd';
import axios from 'axios';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';


const Resume = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const navigate = useNavigate();
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [meetingForm, setMeetingForm] = useState({
    title: '',
    body: '',
    admin_note: '',
    date: null,
  });

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=Resume&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: '',
      body: '',
      admin_note: '',
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format("YYYY-MM-DD HH:mm:ss");
      
      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        resume_id: selectedMeetingFileId,
      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: '',
        body: '',
        admin_note: '',
        date: null,
      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };
  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=Resume&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };
  
  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        resume_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };

  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];


  const handleStatusChange = async (newStatus, recordId) => {
    try {
      await api.put(`/panel/resume/${recordId}`, {
        status: newStatus
      });
      message.success('وضعیت با موفقیت تغییر کرد');
      fetchData(); // Refresh the table data
    } catch (error) {
      message.error('خطا در تغییر وضعیت');
    }
  };

  const getStatusTagColor = (status) => {
    const colors = {
      pending: 'gold',
      in_review: 'blue',
      decline: 'red',
      approve: 'green'
    };
    return colors[status] || 'default';
  };

  const getStatusText = (status) => {
    const statusTexts = {
      pending: 'در انتظار',
      in_review: 'در حال بررسی',
      decline: 'رد شده',
      approve: 'تایید شده'
    };
    return statusTexts[status] || status;
  };


  ///////////////////
  const columns = [
    {
      title: 'شناسه',
      dataIndex: ['id'],
      key: 'id',
    },
    {
      title: 'عنوان',
      dataIndex: ['career_opportunity', 'title'],
      key: 'title',
    },
    {
      title: 'نوع',
      dataIndex: ['career_opportunity', 'type'],
      key: 'type',
    },
    {
      title: 'کشور',
      dataIndex: ['career_opportunity', 'country'],
      key: 'country',
    },
    {
      title: 'تاریخ',
      dataIndex: ['career_opportunity', 'created_at'],
      key: 'salary',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status || 'pending'}
          style={{ width: 130 }}
          onChange={(value) => handleStatusChange(value, record.id)}
          dropdownStyle={{ minWidth: '130px' }}
        >
          <Select.Option value="pending">
            <Tag color="gold">در انتظار</Tag>
          </Select.Option>
          <Select.Option value="in_review">
            <Tag color="blue">در حال بررسی</Tag>
          </Select.Option>
          <Select.Option value="decline">
            <Tag color="red">رد شده</Tag>
          </Select.Option>
          <Select.Option value="approve">
            <Tag color="green">تایید شده</Tag>
          </Select.Option>
        </Select>
      ),
    },
    {
      title: 'دانلود فایل',
      key: 'download',
      render: (record) => (
        <Button 
          type="primary" 
          onClick={() => handleDownload(record.file)}
        >
          دانلود
        </Button>
      ),
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
         
          <Button
          className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          >
            یادداشت
          </Button>
          <Button
          className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          >
            جلسه
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get('/panel/resume', {
        params: {
          page: 1,
          per_page: 50
        }
      });
      setData(response.data.data);
    } catch (error) {
      console.error('خطا در دریافت اطلاعات:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <div style={{ direction: 'rtl' }}>

<h1 className='mb-10 text-xl'> رزومه‌های ارسال شده</h1>

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={true}
        scroll={{ x: true }}
      />
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) => setMeetingForm({ ...meetingForm, title: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) => setMeetingForm({ ...meetingForm, body: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) => setMeetingForm({ ...meetingForm, admin_note: e.target.value })}
            />
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={!meetingForm.title || !meetingForm.date}
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};

export default Resume;