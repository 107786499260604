// pages/shops/add.js
import React, { useState } from 'react';
import {
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  Button,
  Space,
  Divider,
  message,
  Typography,
} from 'antd';
import {
  UploadOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  SaveOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const AddShop = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [galleryImages, setGalleryImages] = useState([]);
  const [videoFile, setVideoFile] = useState(null);

  const onFinish = (values) => {
    console.log('Form values:', values);
    message.success('فروشگاه با موفقیت ایجاد شد');
    navigate('/shops');
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Card>
      <Space direction="horizontal" style={{ marginBottom: 16 }}>
        <Button 
          icon={<ArrowRightOutlined />} 
          onClick={() => navigate('/shops/list')}
        >
          بازگشت
        </Button>
        <Title level={4}>افزودن فروشگاه جدید</Title>
      </Space>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Card title="تصاویر اصلی" style={{ marginBottom: 24 }}>
          <Form.Item
            name="logo"
            label="لوگو"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>آپلود لوگو</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            name="background"
            label="تصویر پس‌زمینه"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>آپلود تصویر پس‌زمینه</div>
              </div>
            </Upload>
          </Form.Item>
        </Card>

        <Card title="اطلاعات کلی" style={{ marginBottom: 24 }}>
          <Form.Item
            name="title"
            label="نام فروشگاه"
            rules={[{ required: true, message: 'لطفا نام فروشگاه را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات"
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="تلفن"
            rules={[{ required: true, message: 'لطفا تلفن را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tags"
            label="تگ‌ها"
          >
            <Select mode="tags" style={{ width: '100%' }} placeholder="تگ‌ها را وارد کنید">
            </Select>
          </Form.Item>

          <Form.Item
            name="type"
            label="نوع فروشگاه"
            rules={[{ required: true, message: 'لطفا نوع فروشگاه را انتخاب کنید' }]}
          >
            <Select>
              <Option value="retail">خرده‌فروشی</Option>
              <Option value="wholesale">عمده‌فروشی</Option>
              <Option value="service">خدماتی</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="address"
            label="آدرس"
            rules={[{ required: true, message: 'لطفا آدرس را وارد کنید' }]}
          >
            <TextArea rows={3} />
          </Form.Item>
        </Card>

        <Card title="اطلاعات سفارش" style={{ marginBottom: 24 }}>
          <Form.Item
            name="commission"
            label="کمیسیون ادمین (درصد)"
            rules={[{ required: true, message: 'لطفا درصد کمیسیون را وارد کنید' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="tax"
            label="مالیات (درصد)"
            rules={[{ required: true, message: 'لطفا درصد مالیات را وارد کنید' }]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Card>

        <Card title="گالری و ویدیو" style={{ marginBottom: 24 }}>
          <Form.Item
            name="gallery"
            label="تصاویر گالری"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              multiple
              beforeUpload={() => false}
              onChange={({ fileList }) => setGalleryImages(fileList)}
            >
              <div>
                <PictureOutlined />
                <div style={{ marginTop: 8 }}>آپلود تصویر</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            name="video"
            label="ویدیو"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={({ fileList }) => setVideoFile(fileList[0])}
            >
              <Button icon={<VideoCameraOutlined />}>آپلود ویدیو</Button>
            </Upload>
          </Form.Item>
        </Card>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            size="large"
          >
            ذخیره فروشگاه
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddShop;