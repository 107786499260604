import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const CategoryEdit = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [bannerFileList, setBannerFileList] = useState([]);
  const [bannerChanged, setBannerChanged] = useState(false);
  const [iconFileList, setIconFileList] = useState([]);
  const [iconChanged, setIconChanged] = useState(false);
  const [charCount, setCharCount] = useState(0); 
  const maxCharLimit = 500; 

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await api.get(`/panel/category/${id}`);
        const categoryData = response.data.data;

        form.setFieldsValue({
          title: categoryData.title,
          description: categoryData.description,
        });

        setEditorContent(categoryData.body);

        if (categoryData.banner) {
          setBannerFileList([
            {
              uid: "-1",
              name: "current-banner.png",
              status: "done",
              url: categoryData.banner,
            },
          ]);
        }

        if (categoryData.icon) {
          setIconFileList([
            {
              uid: "-1",
              name: "current-icon.png",
              status: "done",
              url: categoryData.icon,
            },
          ]);
        }
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      } finally {
        setInitialLoading(false);
      }
    };

    fetchCategory();
  }, [id, form]);

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);

  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("body", editorContent);

      if (bannerChanged) {
        if (bannerFileList.length > 0 && bannerFileList[0].originFileObj) {
          formData.append("banner", bannerFileList[0].originFileObj);
        } else {
          formData.append("remove_banner", "true");
        }
      }

      if (iconChanged) {
        if (iconFileList.length > 0 && iconFileList[0].originFileObj) {
          formData.append("icon", iconFileList[0].originFileObj);
        } else {
          formData.append("remove_icon", "true");
        }
      }

      await api.post(`/panel/category/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("دسته‌بندی با موفقیت ویرایش شد");
      navigate("/categories");
    } catch (error) {
      message.error("خطا در ویرایش اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش دسته‌بندی</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"Category"}
              height={1000}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item
            name="banner"
            label="تصویر بنر"
            rules={[
              {
                required: !bannerFileList.length,
                message: "لطفا تصویر بنر را آپلود کنید",
              },
            ]}
          >
            <Upload
              fileList={bannerFileList}
              onChange={({ fileList }) => {
                setBannerFileList(fileList);
                setBannerChanged(true);
              }}
              maxCount={1}
              beforeUpload={() => false}
              listType="picture"
              onRemove={() => {
                setBannerFileList([]);
                setBannerChanged(true);
              }}
            >
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="icon"
            label="تصویر آیکون"
            rules={[
              {
                required: !iconFileList.length,
                message: "لطفا تصویر آیکون را آپلود کنید",
              },
            ]}
          >
            <Upload
              fileList={iconFileList}
              onChange={({ fileList }) => {
                setIconFileList(fileList);
                setIconChanged(true);
              }}
              maxCount={1}
              beforeUpload={() => false}
              listType="picture"
              onRemove={() => {
                setIconFileList([]);
                setIconChanged(true);
              }}
            >
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button
              onClick={() => navigate("/categories")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CategoryEdit;
