import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Subscriptions = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const fetchFiles = async () => {
        setLoading(true);
        try {
            const response = await api.get('/panel/subscription/all');
            console.log(response)
            setRequests(response.data.data.data);

        } catch (error) {
            message.error('خطا در دریافت اطلاعات');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    
   

    const columns = [
        {
            title: 'ایمیل کاربر',
            dataIndex:'email',
            key: 'email',
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            // render: (date) => date.split(' ')[0], // فقط نمایش تاریخ بدون ساعت
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
        },
        // {
        //     title: 'عملیات',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Button 
        //                 type="primary"
        //                 icon={<EditOutlined />}
        //                 onClick={() => navigate(`/immigration-files/edit/${record.id}`)}
        //             >
        //                 ویرایش
        //             </Button>
        //             <Button 
        //                 type="primary" 
        //                 danger
        //                 icon={<DeleteOutlined />}
        //                 onClick={() => handleDelete(record.id)}
        //             >
        //                 حذف
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    return (
        <div style={{ padding: 24 }}>
            <h1 className='text-xl mb-10'> خبرنامه </h1>
            <Table
                columns={columns}
                dataSource={requests}
                loading={loading}
                rowKey="id"
                pagination={{
                    total: requests.length,
                    pageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `مجموع ${total} درخواست`
                }}
            />
        </div>
    );
};

export default Subscriptions;