import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const AddSamples = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500;

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/plan");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Helper function to check if a value exists and is not empty
      const appendIfExists = (key, value) => {
        if (value !== undefined && value !== null && value !== "") {
          formData.append(key, value);
        }
      };

      // Required fields
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("body", editorContent);
      formData.append("plan_id", values.plan_id);
      appendIfExists("video_tag", values.video_tag);

      // Optional fields
      appendIfExists("video_source", values.video_source);
      appendIfExists("video_tag", values.video_tag);
      appendIfExists("voice_title", values.voice_title);
      appendIfExists("voice_subtitle", values.voice_subtitle);

      // File uploads
      if (values.banner?.file) {
        formData.append("banner", values.banner.file);
      }

      if (values.voice_profile_img?.file) {
        formData.append("voice_profile_img", values.voice_profile_img.file);
      }

      if (values.voice?.file) {
        formData.append("voice", values.voice.file);
      }

      if (values.video?.file) {
        formData.append("video", values.video.file);
      }

      // Log formData for debugging
      for (let pair of formData.entries()) {
        console.log("FormData:", pair[0], pair[1]);
      }

      const response = await api.post("/panel/successful-example", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("نمونه موفق با موفقیت ایجاد شد");
      navigate("/success-samples");
    } catch (error) {
      message.error("خطا در ثبت اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">افزودن نمونه موفق جدید</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="اسم"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"SuccessfulExample "}
              height={1000}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item
            name="plan_id"
            label="برنامه مهاجرتی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
            />
          </Form.Item>

          <Form.Item name="video_source" label="ویدیو">
            <Input />
          </Form.Item>
          <Form.Item name="video_tag" label="تگ ویدیو">
            <Input />
          </Form.Item>
          <Form.Item
            name="video"
            label="فایل ویدیو"
            rules={[
              {
                required: false, 
                message: "لطفا فایل ویدیو را آپلود کنید",
              },
            ]}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              accept="video/*"
              listType="picture"
            >
              <Button icon={<UploadOutlined />} loading={uploading}>
                آپلود ویدیو
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="banner"
            label="تصویر بنر"
            rules={[
              {
                required: true,
                message: "لطفا تصویر بنر را آپلود کنید",
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>
          {/* بخش پادکست */}
          <div className="border p-4 rounded-lg mb-4">
            <h3 className="text-lg mb-4">پادکست</h3>

            <Form.Item name="voice_title" label="عنوان پادکست">
              <Input />
            </Form.Item>

            <Form.Item name="voice_subtitle" label="زیرعنوان پادکست">
              <Input />
            </Form.Item>

            <Form.Item name="voice_profile_img" label="آیکون پادکست">
              <Upload
                maxCount={1}
                beforeUpload={() => false}
                listType="picture"
                accept="image/*"
              >
                <Button icon={<UploadOutlined />} loading={uploading}>
                  آپلود آیکون
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item name="voice" label="فایل پادکست">
              <Upload maxCount={1} beforeUpload={() => false} accept="audio/*">
                <Button icon={<UploadOutlined />} loading={uploading}>
                  آپلود فایل پادکست
                </Button>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ثبت
            </Button>
            <Button
              onClick={() => navigate("/panel/immigration-programs")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AddSamples;
