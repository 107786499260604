// CommentsPage.jsx
import React, { useState, useEffect } from 'react';
import { Table, Switch, Button, Modal, Form, Input, message, Space, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from '../../services/api';

const CommentsPage = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [form] = Form.useForm();

  const fetchComments = async () => {
    setLoading(true);
    try {
      const response = await api.get('/panel/comment');
      setComments(response.data.data);
    } catch (error) {
      message.error('خطا در دریافت کامنت‌ها');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchComments();
  }, []);

  const handleApproveToggle = async (record) => {
    try {
      await api.put(`/panel/comment/${record.id}`, {
        approved: record.approved === 1 ? 0 : 1
      });
      message.success('وضعیت کامنت با موفقیت تغییر کرد');
      fetchComments();
    } catch (error) {
      message.error('خطا در تغییر وضعیت کامنت');
    }
  };

  const handleReply = async (values) => {
    try {
      await api.post('/panel/comment', {
        comment: values.reply,
        parent_id: selectedComment.id
      });
      message.success('پاسخ با موفقیت ثبت شد');
      setReplyModalVisible(false);
      form.resetFields();
      fetchComments();
    } catch (error) {
      message.error('خطا در ثبت پاسخ');
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/panel/comment/${id}`);
      message.success('کامنت با موفقیت حذف شد');
      fetchComments();
    } catch (error) {
      message.error('خطا در حذف کامنت');
    }
  };

  const columns = [
    {
      title: 'نام',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ایمیل',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'نوع',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <span className={`px-2 py-1 rounded ${type === 'Plan' ? 'bg-blue-100' : 'bg-green-100'}`}>
          {type}
        </span>
      ),
    },
    {
      title: 'متن کامنت',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'وضعیت نمایش',
      key: 'approved',
      render: (_, record) => (
        <Switch
          checked={record.approved === 1}
          onChange={() => handleApproveToggle(record)}
        />
      ),
    },
    {
      title: 'عملیات',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<CommentOutlined />}
            onClick={() => {
              setSelectedComment(record);
              setReplyModalVisible(true);
            }}
          >
            پاسخ
          </Button>
          <Popconfirm
            title="آیا از حذف این کامنت اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
          >
            <Button danger icon={<DeleteOutlined />}>
              حذف
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <h1 className="text-2xl mb-6">مدیریت کامنت‌ها</h1>
      
      <Table
        loading={loading}
        columns={columns}
        dataSource={comments}
        rowKey="id"
        expandable={{
          childrenColumnName: 'children',
        }}
      />

      <Modal
        title="پاسخ به کامنت"
        open={replyModalVisible}
        onCancel={() => {
          setReplyModalVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleReply}>
          <Form.Item
            name="reply"
            rules={[{ required: true, message: 'لطفا پاسخ خود را وارد کنید' }]}
          >
            <Input.TextArea rows={4} placeholder="پاسخ خود را بنویسید..." />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              ارسال پاسخ
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CommentsPage;