import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Transfer,
  Space,
  message,
  Popconfirm,
  Checkbox,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { roleService } from "../../services/roleService";
import api from "../../services/api";
import { useParams } from "react-router-dom";

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const { id } = useParams();

  const onChange = (checkedValues) => {
    setSelectedPermissions(checkedValues);
  };

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await roleService.getRoles();
      console.log(response.data);
      setRoles(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت نقش‌ها");
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await roleService.getAllPermissions();
      let arr = [];
      response.data.data.map((perm) => {
        arr.push(perm?.name);
      });

      setPermissions(arr);
    } catch (error) {
      message.error("خطا در دریافت دسترسی‌ها");
    }
  };

  const handleAddEdit = async (id) => {

    setEditingRole(id)

    
    let arr = [];


    const res = await api.get(`/panel/roles/${id}`);
    console.log("danial", res.data.data.name);



    form.setFieldsValue({
      name: res?.data?.data?.name,
      // description: role.description,
      permissions: res?.data?.data?.permissions?.map((p) => p?.name),
    });


    setSelectedPermissions(arr);

    setModalVisible(true);
  };


  useEffect(() => {
    if(modalVisible === false){
      form.setFieldsValue({
        name: null,
        // description: role.description,
        permissions: null,
      });
  
  
      setSelectedPermissions(null);
    }
  } , [modalVisible])

  
  const handleDelete = async (roleId) => {
    try {
      await roleService.deleteRole(roleId);
      message.success("نقش با موفقیت حذف شد");
      fetchRoles();
    } catch (error) {
      message.error("خطا در حذف نقش");
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (editingRole) {
        const res = await roleService.updateRole(editingRole , values);

        if (res?.data?.data?.id) {
          await roleService.syncPermissions({
            role_id: res?.data?.data?.id,
            permissions: selectedPermissions,
          });
        }

        message.success("نقش با موفقیت به‌روزرسانی شد");
      } else {
        const res = await roleService.createRole(values);
        setActiveId(res?.data?.data?.id);

        if (res?.data?.data?.id) {
          await roleService.syncPermissions({
            role_id: res?.data?.data?.id,
            permissions: selectedPermissions,
          });
        }

        message.success("نقش با موفقیت ایجاد شد");
      }
      setModalVisible(false);
      fetchRoles();
    } catch (error) {
      message.error("خطا در ذخیره نقش");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "نام نقش",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "عملیات",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleAddEdit(record.id)}
          ></Button>
          <Popconfirm
            title="آیا از حذف این نقش اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
          >
            <Button danger icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl">مدیریت نقش‌ها</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setModalVisible(true)}
        >
          افزودن نقش جدید
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={roles}
        rowKey="id"
        loading={loading}
      />

      <Modal
        title={editingRole ? "ویرایش نقش" : "افزودن نقش جدید"}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="نام نقش"
            rules={[{ required: true, message: "نام نقش الزامی است" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="permissions" label="دسترسی‌ها">
            <Checkbox.Group
              options={permissions}
              onChange={onChange}
              defaultValue={selectedPermissions}
            />
          </Form.Item>

          <Form.Item className="text-left">
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                {editingRole ? "به‌روزرسانی" : "ایجاد"}
              </Button>
              <Button onClick={() => setModalVisible(false)}>انصراف</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoleManagement;
