import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  Card,
  Row,
  Col,
  Descriptions,
  message,
  Space,
  Input,
} from "antd";
import {
  DownloadOutlined,
  UserOutlined,
  FileOutlined,
  MessageOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../services/api";

const { TextArea } = Input;

const EditImmigrationFile = () => {
  const [form] = Form.useForm();
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const statusOptions = [
    { label: " در انتظار دریافت مدرک", value: "pending_document_upload" },
    { label: "تحویل مدارک", value: "document_upload_completed" },
    { label: " تایید مدارک", value: "submit" },
    { label: " اپروال کلاینت", value: "client_approval" },
    { label: "پایان پرونده", value: "completed" },
  ];

  const priorityOptions = [
    { label: "فوری", value: "urgent" },
    { label: "مهم", value: "important" },
    { label: "عادی", value: "normal" },
    { label: "غیر مهم", value: "unimportant" },
  ];

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await api.get(
        `https://api.hamidehsakak.com/api/v1/panel/immigration-file/${id}?includes[]=user`
      );
      setFileData(response.data.data);
      form.setFieldsValue({
        status: response.data.data.status,
        priority: response.data.data.priority,
        admin_message: response.data.data.admin_message,
        visa_status:response.data.data.visa_status,
      });
    } catch (error) {
      message.error("Failed to fetch data");
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await api.put(
        `https://api.hamidehsakak.com/api/v1/panel/immigration-file/${id}`,
        {
          status: values.status,
          priority: values.priority,
          admin_message: values.admin_message,
          visa_status: values.visa_status,

        }
      );
      message.success("اطلاعات با موفقیت ذخیره شد");
      navigate("/immigration-files");
    } catch (error) {
      message.error("خطا در ذخیره اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!fileData) return <div>Loading...</div>;

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[16, 16]} style={{ padding: "24px" }}>
        <Col span={24}>
          <Card
            title={
              <Space>
                <FileOutlined /> بررسی پرونده مهاجرتی
              </Space>
            }
            bordered={false}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="وضعیت"
                  name="status"
                  rules={[
                    { required: true, message: "لطفا وضعیت را انتخاب کنید" },
                  ]}
                >
                  <Select options={statusOptions} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="اولویت"
                  name="priority"
                  rules={[
                    { required: true, message: "لطفا اولویت را انتخاب کنید" },
                  ]}
                >
                  <Select options={priorityOptions} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="visa_status"
                label="وضعیت ویزا"
                rules={[
                  {
                    required: true,
                    message: "لطفا ویزا را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title={
              <Space>
                <MessageOutlined /> پیام ادمین
              </Space>
            }
            bordered={false}
          >
            <Form.Item
              name="admin_message"
              rules={[{ required: true, message: "لطفا پیام را وارد کنید" }]}
            >
              <TextArea rows={4} placeholder="پیام خود را وارد کنید..." />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title={
              <Space>
                <UserOutlined /> اطلاعات کاربر
              </Space>
            }
            bordered={false}
          >
            <Descriptions column={{ xs: 1, sm: 2, md: 3 }}>
              <Descriptions.Item label="نام">
                {fileData.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="ایمیل">
                {fileData.user.email}
              </Descriptions.Item>
              <Descriptions.Item label="شماره موبایل">
                {fileData.user.mobile}
              </Descriptions.Item>
              <Descriptions.Item label=" شماره پاسپورت">
                {fileData.user.passport_number}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title={
              <Space>
                <DownloadOutlined /> مدارک
              </Space>
            }
            bordered={false}
          >
            <Row gutter={[16, 16]}>
              {fileData.certificate && (
                <Col xs={24} sm={12} md={6}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      handleDownload(
                        fileData.certificate.url,
                        fileData.certificate.name
                      )
                    }
                    block
                  >
                    مدرک کلاینت
                  </Button>
                </Col>
              )}

              {fileData.education && (
                <Col xs={24} sm={12} md={6}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      handleDownload(
                        fileData.education.url,
                        fileData.education.name
                      )
                    }
                    block
                  >
                    مدرک تحصیلی کلاینت
                  </Button>
                </Col>
              )}

              {fileData.financial && (
                <Col xs={24} sm={12} md={6}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      handleDownload(
                        fileData.financial.url,
                        fileData.financial.name
                      )
                    }
                    block
                  >
                    مدرک مالی کلاینت
                  </Button>
                </Col>
              )}

              {fileData.translate && (
                <Col xs={24} sm={12} md={6}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      handleDownload(
                        fileData.translate.url,
                        fileData.translate.name
                      )
                    }
                    block
                  >
                    مدرک ترجمه کلاینت
                  </Button>
                </Col>
              )}
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button onClick={handleBack}>بازگشت</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default EditImmigrationFile;
