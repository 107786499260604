import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ children, requiredPermissions = [] }) => {
  const { user, loading, hasPermission } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; 
  }

//   if (!user) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

  if (requiredPermissions.length > 0 && 
      !requiredPermissions.every(permission => hasPermission(permission))) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PrivateRoute;