import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Unauthorized = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <Result
        status="403"
        title="دسترسی غیرمجاز"
        subTitle={
          <div className="text-center">
            <p className="mb-2">
              {user ? (
                `${user.name} عزیز، شما مجوز دسترسی به این صفحه را ندارید.`
              ) : (
                'شما مجوز دسترسی به این صفحه را ندارید.'
              )}
            </p>
          </div>
        }
        extra={[
          <Button type="primary" key="home" onClick={goHome}>
            بازگشت به صفحه اصلی
          </Button>,
          <Button key="back" onClick={goBack}>
            بازگشت به صفحه قبل
          </Button>,
        ]}
      />
    </div>
  );
};

export default Unauthorized;