import { Form, Input, Button, Card, Alert, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const backgroundStyle = {
    backgroundImage: `url(${require("../../images/32991.jpg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  };

  const from = location.state?.from?.pathname || "/";

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setError("");

      const result = await login(values);

      if (result.success) {
        navigate("/");
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("خطا در ورود به سیستم");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={backgroundStyle}>
      <Card className="w-full max-w-md shadow-lg">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold">ورود به پنل مدیریت</h2>
          {/* <p className="text-gray-500">لطفا اطلاعات خود را وارد کنید</p> */}
        </div>

        {error && (
          <Alert message={error} type="error" showIcon className="mb-4" />
        )}

        <Form name="login" onFinish={onFinish} layout="vertical" size="large">
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "لطفا نام کاربری را وارد کنید" },
              // { min: 3, message: 'نام کاربری باید حداقل 3 کاراکتر باشد' }
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="نام کاربری"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "لطفا رمز عبور را وارد کنید" },
              { min: 6, message: "رمز عبور باید حداقل 6 کاراکتر باشد" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="رمز عبور"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item>
            <div className="flex justify-between items-center">
              <Checkbox>مرا به خاطر بسپار</Checkbox>
              <a className="text-primary" href="/forgot-password">
                فراموشی رمز عبور
              </a>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              className="rounded-lg"
            >
              ورود
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Login;
