import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const PodcastAdd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/plan");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Helper function to check if a value exists and is not empty
      const appendIfExists = (key, value) => {
        if (value !== undefined && value !== null && value !== "") {
          formData.append(key, value);
        }
      };

      formData.append("title", values.title);
      formData.append("subtitle", values.subtitle);
      formData.append("plan_id", values.plan_id);


      if (values.profile_img?.file) {
        formData.append("profile_img", values.profile_img.file);
      }

      if (values.voice?.file) {
        formData.append("voice", values.voice.file);
      }

      // Log formData for debugging
      for (let pair of formData.entries()) {
        console.log("FormData:", pair[0], pair[1]);
      }

      const response = await api.post("/panel/podcast", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("نمونه موفق با موفقیت ایجاد شد");
      navigate("/podcast");
    } catch (error) {
      message.error("خطا در ثبت اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">افزودن پادکست</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="subtitle"
            label="زیرعنوان"
            rules={[
              {
                required: true,
                message: "لطفا زیرعنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="plan_id"
            label="برنامه مهاجرتی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="profile_img" label="کاور پادکست">
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  listType="picture"
                  accept="image/*"
                >
                  <Button icon={<UploadOutlined />} loading={uploading}>
                    آپلود آیکون
                  </Button>
                </Upload>
              </Form.Item>
            </Col >
            <Col span={12}>
              <Form.Item name="voice" label="فایل پادکست">
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  accept="audio/*"
                >
                  <Button icon={<UploadOutlined />} loading={uploading}>
                    آپلود فایل پادکست
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ثبت
            </Button>
            <Button
              onClick={() => navigate("/podcast")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default PodcastAdd;
