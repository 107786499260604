// AdminSellerManagement.js
import React, { useState } from 'react';
import {
  Table,
  Card,
  Input,
  Select,
  Button,
  Space,
  Tag,
  Row,
  Col,
  Statistic,
  Badge,
  Popconfirm,
  message,
  Typography
} from 'antd';
import {
  SearchOutlined,
  CheckCircleOutlined,
  StopOutlined,
  EyeOutlined,
  UserOutlined,
  ShopOutlined,
  DollarOutlined
} from '@ant-design/icons';
import './AdminSellerManagement.css';

const { Title } = Typography;
const { Option } = Select;

const AdminSellerManagement = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');

  const data = [
    {
      key: '1',
      id: 1,
      name: "فروشگاه نمونه",
      owner: "علی محمدی",
      status: "active",
      joinDate: "1403/05/12",
      totalSales: 25000000,
      productsCount: 48,
      commission: 15,
      balance: 8500000,
      verified: true
    },
    // ... سایر داده‌ها
  ];

  const handleVerify = (sellerId) => {
    message.success('فروشنده با موفقیت تایید شد');
  };

  const handleBlock = (sellerId) => {
    message.warning('فروشنده مسدود شد');
  };

  const getStatusTag = (status) => {
    const statusMap = {
      active: { color: 'success', text: 'فعال' },
      pending: { color: 'warning', text: 'در انتظار تایید' },
      blocked: { color: 'error', text: 'مسدود' }
    };
    return (
      <Tag color={statusMap[status].color}>
        {statusMap[status].text}
      </Tag>
    );
  };

  const columns = [
    {
      title: 'نام فروشگاه',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          {text}
          {record.verified && (
            <Badge status="processing" color="blue" />
          )}
        </Space>
      ),
    },
    {
      title: 'مدیر',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      render: status => getStatusTag(status),
    },
    {
      title: 'تاریخ عضویت',
      dataIndex: 'joinDate',
      key: 'joinDate',
    },
    {
      title: 'فروش کل (تومان)',
      dataIndex: 'totalSales',
      key: 'totalSales',
      render: value => new Intl.NumberFormat('fa-IR').format(value),
    },
    {
      title: 'تعداد محصولات',
      dataIndex: 'productsCount',
      key: 'productsCount',
    },
    {
      title: 'کمیسیون (%)',
      dataIndex: 'commission',
      key: 'commission',
    },
    {
      title: 'موجودی (تومان)',
      dataIndex: 'balance',
      key: 'balance',
      render: value => new Intl.NumberFormat('fa-IR').format(value),
    },
    {
      title: 'عملیات',
      key: 'action',
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="primary" 
            icon={<EyeOutlined />}
            onClick={() => window.location.href = `/admin/sellers/${record.id}`}
          >
            مشاهده
          </Button>
          {!record.verified && (
            <Popconfirm
              title="آیا از تایید این فروشنده اطمینان دارید؟"
              onConfirm={() => handleVerify(record.id)}
              okText="بله"
              cancelText="خیر"
            >
              <Button 
                type="default" 
                icon={<CheckCircleOutlined />}
                style={{ background: '#52c41a', color: 'white' }}
              >
                تایید
              </Button>
            </Popconfirm>
          )}
          <Popconfirm
            title="آیا از مسدود کردن این فروشنده اطمینان دارید؟"
            onConfirm={() => handleBlock(record.id)}
            okText="بله"
            cancelText="خیر"
          >
            <Button 
              danger 
              icon={<StopOutlined />}
            >
              مسدود
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="admin-seller-management">
      <Card>
        <Title level={4}>مدیریت فروشندگان</Title>
        
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col span={6}>
            <Card>
              <Statistic
                title="کل فروشندگان"
                value={data.length}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="فروشندگان فعال"
                value={data.filter(s => s.status === 'active').length}
                prefix={<ShopOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="در انتظار تایید"
                value={data.filter(s => s.status === 'pending').length}
                prefix={<CheckCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="مجموع فروش (میلیون تومان)"
                value={data.reduce((acc, curr) => acc + curr.totalSales, 0) / 1000000}
                prefix={<DollarOutlined />}
                precision={2}
              />
            </Card>
          </Col>
        </Row>

        <Space style={{ marginBottom: 16 }}>
          <Input
            placeholder="جستجوی فروشنده..."
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ width: 200 }}
          />
          <Select 
            defaultValue="all"
            style={{ width: 150 }}
            onChange={value => setFilterStatus(value)}
          >
            <Option value="all">همه وضعیت‌ها</Option>
            <Option value="active">فعال</Option>
            <Option value="pending">در انتظار تایید</Option>
            <Option value="blocked">مسدود</Option>
          </Select>
        </Space>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            total: data.length,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `مجموع ${total} فروشنده`
          }}
        />
      </Card>
    </div>
  );
};

export default AdminSellerManagement;