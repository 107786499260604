import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const EditSamples = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [existingBanner, setExistingBanner] = useState(null);
  const [existingIcon, setExistingIcon] = useState(null);
  const [existingVoice, setExistingVoice] = useState(null);
  const [existingVideo, setExistingVideo] = useState(null);
  const [charCount, setCharCount] = useState(0); 
  const maxCharLimit = 500; 

  

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);
  };

 

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/panel/successful-example/${id}`);
        const data = response.data.data;
        setInitialData(data);
        setEditorContent(data.body);
        setExistingBanner(data.banner);
        setExistingIcon(data.voice_profile_img);
        setExistingVoice(data.voice);
        setExistingVideo(data?.video)

        form.setFieldsValue({
          title: data.title || "",
          description: data.description || "",
          plan_id: data.plan?.id || "",
          video_source: data.video_source || "",
          video_tag: data.video_tag || "",
          voice_title: data.voice_title || "",
          voice_subtitle: data.voice_subtitle || "",
        });
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      }
      setLoading(false);
    };

    fetchData();
  }, [id, form]);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/plan");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("body", editorContent);
      formData.append("plan_id", values.plan_id);
      formData.append("video_source", values.video_source);
      formData.append("video_tag", values.video_tag);
      formData.append("voice_title", values.voice_title);
      formData.append("voice_subtitle", values.voice_subtitle);

      if (values.banner?.file) {
        formData.append("banner", values.banner.file);
      }

      if (values.voice_profile_img?.file) {
        formData.append("voice_profile_img", values.voice_profile_img.file);
      }

      if (values.voice?.file) {
        formData.append("voice", values.voice.file);
      }

      await api.post(`/panel/successful-example/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("نمونه موفق با موفقیت ویرایش شد");
      navigate("/success-samples");
    } catch (error) {
      message.error("خطا در ویرایش اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش نمونه موفق</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="اسم"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"SuccessfulExample "}
              height={1000}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item
            name="plan_id"
            label="برنامه مهاجرتی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
            />
          </Form.Item>

          <Form.Item name="video_source" label="ویدیو">
            <Input />
          </Form.Item>

          <Form.Item name="video_tag" label="تگ ویدیو">
            <Input />
          </Form.Item>

          <Form.Item
            name="video"
            label=" آپلود ویدیو"
            extra={
              existingVideo && (
                <img
                  src={existingVideo}
                  alt=" ویدیو موجود است"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingVideo ? "تغییر ویدیو" : "انتخاب ویدیو"}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="banner"
            label="تصویر بنر"
            extra={
              existingBanner && (
                <img
                  src={existingBanner}
                  alt="Current banner"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingBanner ? "تغییر تصویر" : "انتخاب تصویر"}
              </Button>
            </Upload>
          </Form.Item>

          <div className="border p-4 rounded-lg mb-4">
            <h3 className="text-lg mb-4">پادکست</h3>

            <Form.Item name="voice_title" label="عنوان پادکست">
              <Input />
            </Form.Item>

            <Form.Item name="voice_subtitle" label="زیرعنوان پادکست">
              <Input />
            </Form.Item>

            <Form.Item
              name="voice_profile_img"
              label="آیکون پادکست"
              extra={
                existingIcon && (
                  <img
                    src={existingIcon}
                    alt="Current icon"
                    style={{ maxWidth: "200px" }}
                  />
                )
              }
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button icon={<UploadOutlined />} loading={uploading}>
                  {existingIcon ? "تغییر تصویر" : "انتخاب تصویر"}
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name="voice"
              label="فایل پادکست"
              extra={
                existingVoice && (
                  <audio controls>
                    <source src={existingVoice} type="audio/mpeg" />
                    مرورگر شما از پخش صوت پشتیبانی نمی‌کند.
                  </audio>
                )
              }
            >
              <Upload maxCount={1} beforeUpload={() => false} listType="audio">
                <Button icon={<UploadOutlined />} loading={uploading}>
                  {existingVoice ? "تغییر پادکست" : "انتخاب پادکست"}
                </Button>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button
              onClick={() => navigate("/success-samples")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditSamples;
