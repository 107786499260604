import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const AddTestimonial = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };
  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/category"); 
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  // آپلود تصویر
//   const handleUpload = async (file) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     setUploading(true);
//     try {
//       const response = await api.post("/panel/upload", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       return response.data.url; // آدرس فایل آپلود شده
//     } catch (error) {
//       message.error("خطا در آپلود تصویر");
//       return "";
//     } finally {
//       setUploading(false);
//     }
//   };

  // ارسال فرم
  const onFinish = async (values) => {
    setLoading(true);
    try {
      // ایجاد FormData برای ارسال فایل
      const formData = new FormData();
      
      // اضافه کردن فایل به FormData اگر وجود داشته باشد
      if (values.profile_img?.file) {
        formData.append('profile_img', values.profile_img.file);
      }

      // اضافه کردن سایر فیلدها به FormData
      formData.append('title', values.title);
      formData.append('country', values.country);
      formData.append('rate', values.rate);
      formData.append('body', editorContent);

      await api.post("/panel/testimonial", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      message.success("نظر با موفقیت ایجاد شد");
      navigate("/testimonial");
    } catch (error) {
      message.error("خطا در ایجاد نظر");
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">افزودن نظر</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="اسم"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="کشور"
                rules={[
                  {
                    required: true,
                    message: "لطفا عنوان را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="rate"
                label="امتیازدهی"
                rules={[
                  {
                    required: true,
                    message: "لطفا امتیاز را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="profile_img"
            label="تصویر پروفایل"
            rules={[
              {
                required: true,
                message: "لطفا تصویر پروفایل را آپلود کنید",
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>

          

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
           
        
          </Form.Item>

          <TinyEditor
            content={editorContent}
            onEditorChange={handleEditorChange}
            model={"Comment"}
            height={1000}
          />

          

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ثبت
            </Button>
            <Button
              onClick={() => navigate("/panel/testimonial")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AddTestimonial;
