import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const PodcastEdit = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [existingBanner, setExistingBanner] = useState(null);
  const [existingIcon, setExistingIcon] = useState(null);
  const [existingVoice, setExistingVoice] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/plan");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/panel/podcast/${id}`);
        const data = response.data.data;
        setInitialData(data);
        setExistingIcon(data.profile_img);
        setExistingVoice(data.voice);

        form.setFieldsValue({
          title: data.title || "",
          subtitle: data.subtitle || "",
          plan_id: data.plan?.id || "",

        });
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      }
      setLoading(false);
    };

    fetchData();
  }, [id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("subtitle", values.subtitle);
      formData.append("plan_id", values.plan_id);


      if (values.profile_img?.file) {
        formData.append("profile_img", values.profile_img.file);
      }

      if (values.voice?.file) {
        formData.append("voice", values.voice.file);
      }

      await api.post(`/panel/podcast/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success(" پادکست با موفقیت ویرایش شد");
      navigate("/podcast");
    } catch (error) {
      message.error("خطا در ویرایش پادکست");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش پادکست</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="subtitle"
            label="زیرعنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="plan_id"
            label="برنامه مهاجرتی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
            />
          </Form.Item>

          <Form.Item
            name="profile_img"
            label="کاور پادکست"
            extra={
              existingIcon && (
                <img
                  src={existingIcon}
                  alt="Current icon"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingIcon ? "تغییر تصویر" : "انتخاب تصویر"}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="voice"
            label="فایل پادکست"
            extra={
              existingVoice && (
                <audio controls>
                  <source src={existingVoice} type="audio/mpeg" />
                  مرورگر شما از پخش صوت پشتیبانی نمی‌کند.
                </audio>
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="audio">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingVoice ? "تغییر پادکست" : "انتخاب پادکست"}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button
              onClick={() => navigate("/podcast")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default PodcastEdit;
