import { useState, useEffect } from 'react';
import { Tree, Card, Button, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const CategoryTree = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      // اینجا باید API call برای دریافت دسته‌بندی‌ها اضافه شود
      const mockData = [
        {
          title: 'الکترونیک',
          key: '1',
          children: [
            {
              title: 'موبایل',
              key: '1-1',
              children: [
                { title: 'گوشی هوشمند', key: '1-1-1' },
                { title: 'لوازم جانبی', key: '1-1-2' },
              ],
            },
            {
              title: 'لپ تاپ',
              key: '1-2',
              children: [
                { title: 'لپ تاپ گیمینگ', key: '1-2-1' },
                { title: 'لپ تاپ اداری', key: '1-2-2' },
              ],
            },
          ],
        },
      ];
      setCategories(mockData);
    } catch (error) {
      message.error('خطا در دریافت دسته‌بندی‌ها');
    } finally {
      setLoading(false);
    }
  };

  const onDrop = async (info) => {
    try {
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

      // اینجا باید API call برای بروزرسانی ترتیب دسته‌بندی‌ها اضافه شود
      message.success('ترتیب دسته‌بندی‌ها با موفقیت بروزرسانی شد');
    } catch (error) {
      message.error('خطا در بروزرسانی ترتیب دسته‌بندی‌ها');
    }
  };

  return (
    <Card title="نمایش درختی دسته‌بندی‌ها" loading={loading}>
      <Tree
        className="drag-tree"
        defaultExpandAll
        showLine
        switcherIcon={<DownOutlined />}
        draggable
        onDrop={onDrop}
        treeData={categories}
      />
    </Card>
  );
};

export default CategoryTree;