import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Button,
  Space,
  Input,
  Form,
  Modal,
  Tag,
  Popconfirm,
  message,
  Select
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';

const Tags = () => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [searchText, setSearchText] = useState('');

  // فرض می‌کنیم این توابع به API متصل هستند
  const fetchTags = async () => {
    setLoading(true);
    try {
      // API call به جای این خط
      const response = await fetch('/api/tags');
      const data = await response.json();
      setTags(data);
    } catch (error) {
      message.error('خطا در دریافت برچسب‌ها');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = async (values) => {
    try {
      // API call
      await fetch('/api/tags', {
        method: 'POST',
        body: JSON.stringify(values),
      });
      message.success('برچسب با موفقیت اضافه شد');
      setModalVisible(false);
      form.resetFields();
      fetchTags();
    } catch (error) {
      message.error('خطا در افزودن برچسب');
    }
  };

  const handleEdit = async (values) => {
    try {
      // API call
      await fetch(`/api/tags/${editingTag.id}`, {
        method: 'PUT',
        body: JSON.stringify(values),
      });
      message.success('برچسب با موفقیت ویرایش شد');
      setModalVisible(false);
      setEditingTag(null);
      form.resetFields();
      fetchTags();
    } catch (error) {
      message.error('خطا در ویرایش برچسب');
    }
  };

  const handleDelete = async (id) => {
    try {
      // API call
      await fetch(`/api/tags/${id}`, {
        method: 'DELETE',
      });
      message.success('برچسب با موفقیت حذف شد');
      fetchTags();
    } catch (error) {
      message.error('خطا در حذف برچسب');
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const columns = [
    {
      title: 'عنوان',
      dataIndex: 'title',
      key: 'title',
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.title.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'توضیحات',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? 'فعال' : 'غیرفعال'}
        </Tag>
      ),
    },
    {
      title: 'عملیات',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingTag(record);
              form.setFieldsValue(record);
              setModalVisible(true);
            }}
          >
            ویرایش
          </Button>
          <Popconfirm
            title="آیا از حذف این برچسب اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              حذف
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title="مدیریت برچسب‌ها"
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingTag(null);
            form.resetFields();
            setModalVisible(true);
          }}
        >
          افزودن برچسب
        </Button>
      }
    >
      <Input.Search
        placeholder="جستجو در برچسب‌ها"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16 }}
      />

      <Table
        columns={columns}
        dataSource={tags}
        loading={loading}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
          showTotal: (total) => `مجموع: ${total} برچسب`,
        }}
      />

      <Modal
        title={editingTag ? 'ویرایش برچسب' : 'افزودن برچسب جدید'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingTag(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={editingTag ? handleEdit : handleAdd}
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              { required: true, message: 'لطفاً عنوان را وارد کنید' },
              { min: 2, message: 'عنوان باید حداقل 2 کاراکتر باشد' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="توضیحات">
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="status"
            label="وضعیت"
            initialValue="active"
          >
            <Select>
              <Select.Option value="active">فعال</Select.Option>
              <Select.Option value="inactive">غیرفعال</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingTag ? 'ویرایش' : 'افزودن'}
              </Button>
              <Button onClick={() => {
                setModalVisible(false);
                setEditingTag(null);
                form.resetFields();
              }}>
                انصراف
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default Tags;