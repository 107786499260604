// components/products/steps/SeoInfo.js
import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const SeoInfo = ({ data, updateData }) => {
  const [form] = Form.useForm();

  const onValuesChange = (_, allValues) => {
    updateData(allValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="metaTitle"
        label="عنوان متا"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="metaDescription"
        label="توضیحات متا"
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="metaKeywords"
        label="کلمات کلیدی"
      >
        <TextArea rows={2} />
      </Form.Item>

      <Form.Item
        name="slug"
        label="اسلاگ"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default SeoInfo;