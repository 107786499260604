import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  Tag,
  Space,
  Button,
  Modal,
  Descriptions,
  Form,
  Select,
  Input,
  DatePicker,
  ConfigProvider,
  Drawer,
  Timeline,
  Badge,
  message,
  Tooltip,
  Row,
  Col,
  Statistic
} from 'antd';
import {
  SearchOutlined,
  FilterOutlined,
  PrinterOutlined,
  EyeOutlined,
  EditOutlined,
  HistoryOutlined,
  DollarOutlined,
  ShoppingOutlined,
  UserOutlined,
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import moment from 'moment-jalaali';
import fa_IR from 'antd/lib/locale/fa_IR';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

// تنظیمات moment-jalaali
moment.loadPersian({
  usePersianDigits: true,
  dialect: 'persian-modern'
});

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [filterForm] = Form.useForm();
  const [editStatusForm] = Form.useForm();
  const [editStatusVisible, setEditStatusVisible] = useState(false);
  const [statistics, setStatistics] = useState({
    total: 0,
    pending: 0,
    processing: 0,
    completed: 0,
    cancelled: 0,
  });

  // وضعیت‌های سفارش
  const orderStatuses = {
    pending: { text: 'در انتظار پرداخت', color: 'gold' },
    paid: { text: 'پرداخت شده', color: 'green' },
    processing: { text: 'در حال پردازش', color: 'blue' },
    shipped: { text: 'ارسال شده', color: 'cyan' },
    delivered: { text: 'تحویل داده شده', color: 'green' },
    cancelled: { text: 'لغو شده', color: 'red' },
    refunded: { text: 'مسترد شده', color: 'purple' },
  };

  useEffect(() => {
    fetchOrders();
    fetchStatistics();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      // API call to fetch orders
      // const response = await axios.get('/api/orders');
      // setOrders(response.data);
      
      // Mock data
      setOrders([
        {
          id: 1,
          orderNumber: 'ORD-1001',
          customerName: 'علی محمدی',
          customerPhone: '09121234567',
          totalAmount: 1250000,
          status: 'processing',
          createdAt: '1402/08/12',
          items: [
            { id: 1, name: 'محصول 1', quantity: 2, price: 450000 },
            { id: 2, name: 'محصول 2', quantity: 1, price: 350000 },
          ],
          address: 'تهران، خیابان ولیعصر، پلاک 123',
          paymentMethod: 'online',
          shippingMethod: 'express',
          trackingCode: 'TRK123456',
        },
        // ... more orders
      ]);
    } catch (error) {
      message.error('خطا در دریافت لیست سفارشات');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatistics = async () => {
    try {
      // API call to fetch statistics
      // const response = await axios.get('/api/orders/statistics');
      // setStatistics(response.data);
      
      // Mock statistics
      setStatistics({
        total: 150,
        pending: 30,
        processing: 45,
        completed: 65,
        cancelled: 10,
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  const handleSearch = async (values) => {
    try {
      setLoading(true);
      // Implement search logic here
      console.log('Search values:', values);
    } catch (error) {
      message.error('خطا در جستجو');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (values) => {
    try {
      // API call to update order status
      // await axios.put(`/api/orders/${selectedOrder.id}/status`, values);
      message.success('وضعیت سفارش با موفقیت بروزرسانی شد');
      setEditStatusVisible(false);
      fetchOrders();
    } catch (error) {
      message.error('خطا در بروزرسانی وضعیت سفارش');
    }
  };

  const showOrderDetails = (order) => {
    setSelectedOrder(order);
    setDetailsVisible(true);
  };

  const showOrderHistory = (order) => {
    setSelectedOrder(order);
    setHistoryVisible(true);
  };

  const printOrder = (order) => {
    window.print();
  };

  const columns = [
    {
      title: 'شماره سفارش',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'نام مشتری',
      dataIndex: 'customerName',
      key: 'customerName',
      width: 150,
    },
    {
      title: 'شماره تماس',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 130,
    },
    {
      title: 'مبلغ کل',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 150,
      render: (amount) => `${amount.toLocaleString()} تومان`,
    },
    {
      title: 'تاریخ ثبت',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      width: 130,
      render: (status) => (
        <Tag color={orderStatuses[status].color}>
          {orderStatuses[status].text}
        </Tag>
      ),
    },
    {
      title: 'عملیات',
      key: 'actions',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <Space>
          <Tooltip title="مشاهده جزئیات">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => showOrderDetails(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="تاریخچه">
            <Button
              icon={<HistoryOutlined />}
              onClick={() => showOrderHistory(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="تغییر وضعیت">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedOrder(record);
                setEditStatusVisible(true);
              }}
              size="small"
            />
          </Tooltip>
          <Tooltip title="چاپ">
            <Button
              icon={<PrinterOutlined />}
              onClick={() => printOrder(record)}
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <ConfigProvider locale={fa_IR} direction="rtl">
      <div style={{ padding: '20px' }}>
        {/* آمار کلی */}
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={4}>
            <Card>
              <Statistic
                title="کل سفارشات"
                value={statistics.total}
                prefix={<ShoppingOutlined />}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="در انتظار پرداخت"
                value={statistics.pending}
                prefix={<ClockCircleOutlined />}
                valueStyle={{ color: '#faad14' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="در حال پردازش"
                value={statistics.processing}
                prefix={<SyncOutlined spin />}
                valueStyle={{ color: '#1890ff' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="تکمیل شده"
                value={statistics.completed}
                prefix={<CheckCircleOutlined />}
                valueStyle={{ color: '#52c41a' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="لغو شده"
                value={statistics.cancelled}
                prefix={<CloseCircleOutlined />}
                valueStyle={{ color: '#ff4d4f' }}
              />
            </Card>
          </Col>
        </Row>

        {/* فیلترها */}
        <Card style={{ marginBottom: '20px' }}>
          <Form
            form={filterForm}
            layout="vertical"
            onFinish={handleSearch}
          >
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name="orderNumber" label="شماره سفارش">
                  <Input placeholder="جستجو شماره سفارش" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="customerInfo" label="نام/شماره مشتری">
                  <Input placeholder="جستجو نام یا شماره مشتری" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="status" label="وضعیت">
                  <Select
                    placeholder="انتخاب وضعیت"
                    allowClear
                    mode="multiple"
                  >
                    {Object.entries(orderStatuses).map(([key, value]) => (
                      <Option key={key} value={key}>
                        {value.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="dateRange" label="بازه زمانی">
                  <RangePicker
                    style={{ width: '100%' }}
                    format="jYYYY/jMM/jDD"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  جستجو
                </Button>
                <Button
                  style={{ marginRight: 8 }}
                  onClick={() => filterForm.resetFields()}
                >
                  پاک کردن
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        {/* جدول سفارشات */}
        <Card title="لیست سفارشات">
          <Table
            columns={columns}
            dataSource={orders}
            loading={loading}
            scroll={{ x: 1300 }}
            rowKey="id"
            pagination={{
              total: orders.length,
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `مجموع: ${total} سفارش`,
            }}
          />
        </Card>

        {/* مودال جزئیات سفارش */}
        <Drawer
          title="جزئیات سفارش"
          width={800}
          visible={detailsVisible}
          onClose={() => setDetailsVisible(false)}
          extra={
            <Space>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => printOrder(selectedOrder)}
              >
                چاپ سفارش
              </Button>
            </Space>
          }
        >
          {selectedOrder && (
            <>
              <Descriptions title="اطلاعات سفارش" bordered column={2}>
                <Descriptions.Item label="شماره سفارش">
                  {selectedOrder.orderNumber}
                </Descriptions.Item>
                <Descriptions.Item label="تاریخ ثبت">
                  {selectedOrder.createdAt}
                </Descriptions.Item>
                <Descriptions.Item label="نام مشتری">
                  {selectedOrder.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="شماره تماس">
                  {selectedOrder.customerPhone}
                </Descriptions.Item>
                <Descriptions.Item label="آدرس" span={2}>
                  {selectedOrder.address}
                </Descriptions.Item>
                <Descriptions.Item label="روش پرداخت">
                  {selectedOrder.paymentMethod === 'online' ? 'آنلاین' : 'نقدی'}
                </Descriptions.Item>
                <Descriptions.Item label="روش ارسال">
                  {selectedOrder.shippingMethod === 'express' ? 'پست پیشتاز' : 'پست معمولی'}
                </Descriptions.Item>
                {selectedOrder.trackingCode && (
                  <Descriptions.Item label="کد رهگیری">
                    {selectedOrder.trackingCode}
                  </Descriptions.Item>
                )}
              </Descriptions>

              <Table
                title={() => 'اقلام سفارش'}
                columns={[
                  {
                    title: 'نام محصول',
                    dataIndex: 'name',
                    key: 'name',
                  },
                  {
                    title: 'تعداد',
                    dataIndex: 'quantity',
                    key: 'quantity',
                  },
                  {
                    title: 'قیمت واحد',
                    dataIndex: 'price',
                    key: 'price',
                    render: (price) => `${price.toLocaleString()} تومان`,
                  },
                  {
                    title: 'قیمت کل',
                    key: 'total',
                    render: (_, record) =>
                      `${(record.price * record.quantity).toLocaleString()} تومان`,
                  },
                ]}
                dataSource={selectedOrder.items}
                pagination={false}
                summary={(pageData) => {
                  const total = pageData.reduce(
                    (sum, item) => sum + item.price * item.quantity,
                    0
                  );
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3}>جمع کل</Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {total.toLocaleString()} تومان
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </>
          )}
        </Drawer>

        {/* مودال تاریخچه سفارش */}
        <Drawer
          title="تاریخچه سفارش"
          width={500}
          visible={historyVisible}
          onClose={() => setHistoryVisible(false)}
        >
          {selectedOrder && (
            <Timeline mode="left">
              <Timeline.Item color="green">
                ثبت سفارش
                <br />
                <small>{selectedOrder.createdAt}</small>
              </Timeline.Item>
              <Timeline.Item color="blue">
                تایید پرداخت
                <br />
                <small>1402/08/12 15:30</small>
              </Timeline.Item>
              <Timeline.Item color="blue">
                شروع پردازش
                <br />
                <small>1402/08/12 16:45</small>
              </Timeline.Item>
              {/* Add more timeline items based on order history */}
            </Timeline>
          )}
        </Drawer>

        {/* مودال تغییر وضعیت */}
        <Modal
          title="تغییر وضعیت سفارش"
          visible={editStatusVisible}
          onCancel={() => setEditStatusVisible(false)}
          footer={null}
        >
          <Form
            form={editStatusForm}
            onFinish={handleStatusChange}
            layout="vertical"
          >
            <Form.Item
              name="status"
              label="وضعیت جدید"
              rules={[{ required: true, message: 'لطفا وضعیت جدید را انتخاب کنید' }]}
            >
              <Select>
                {Object.entries(orderStatuses).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {value.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="note"
              label="توضیحات"
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                ثبت تغییرات
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default OrdersPage;