
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Statistic, Table, Typography } from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  CommentOutlined,
  PhoneOutlined,
  CheckCircleOutlined,
  UsergroupAddOutlined,
  FolderOutlined,
  AppstoreOutlined,
  AudioOutlined,
  FileDoneOutlined,
  StarOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import api from "../../services/api";

const { Title } = Typography;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await api.get("/panel/dashboard");
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch dashboard data:", error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "شناسه",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "عنوان",
      dataIndex: ["career_opportunity", "title"],
      key: "title",
    },
    {
      title: "کشور",
      dataIndex: ["career_opportunity", "country"],
      key: "country",
    },
    {
      title: "نوع",
      dataIndex: ["career_opportunity", "type"],
      key: "type",
    },
    {
      title: "حقوق",
      dataIndex: ["career_opportunity", "salary"],
      key: "salary",
    },
    {
      title: "فایل",
      dataIndex: "file",
      key: "file",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
           دانلود
        </a>
      ),
    },
  ];

  if (loading) return <div>Loading...</div>;

  const statistics = [
    {
      title: "کاربران",
      value: dashboardData?.users_count,
      icon: <UserOutlined style={{ color: 'green' }} />,
    },
    {
      title: "بلاگ‌ها",
      value: dashboardData?.blogs_count,
      icon: <FileTextOutlined style={{ color: 'blue' }}/>,
    },
    {
      title: " فرصتهای شغلی",
      value: dashboardData?.career_opportunities_count,
      icon: <CommentOutlined style={{ color: 'red' }}/>,
    },
    {
      title: "نظرات",
      value: dashboardData?.comments_count,
      icon: <CommentOutlined style={{ color: 'orange' }}/>,
    },
    {
      title: "درخواست مشاوره",
      value: dashboardData?.consultations_count,
      icon: <PhoneOutlined style={{ color: 'green' }} />,
    },
    
    
    {
      title: " پرونده‌های مهاجرتی",
      value: dashboardData?.immigration_files_count,
      icon: <FolderOutlined style={{ color: 'orange' }}/>,
    },
    
    {
      title: "رزومه‌ها",
      value: dashboardData?.resumes_count,
      icon: <FileDoneOutlined style={{ color: 'blue' }}/>,
    },
    {
      title: "نمونه‌های موفق ",
      value: dashboardData?.successful_examples_count,
      icon: <StarOutlined style={{ color: 'red' }}/>,
    },
    
  ];

  return (
    <div style={{ padding: "24px" }}>

      <Row gutter={[16, 16]}>
        {statistics.map((stat, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card>
              <Statistic
             
                title={stat.title}
                value={stat.value}
                prefix={stat.icon}
              />
            </Card>
          </Col>
        ))}
      </Row>

      <div className="mt-10" style={{ marginTop: "24px" }}>
        <Title level={3}> آخرین رزومه‌ها</Title>
        <Table
          columns={columns}
          dataSource={dashboardData?.latest_resumes}
          rowKey="id"
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};

export default Dashboard;
