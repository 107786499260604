// Features.jsx
import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Button,
  Space,
  Input,
  Form,
  Modal,
  Tag,
  Popconfirm,
  message,
  Select,
  Tabs,
  List,
  Typography
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { TabPane } = Tabs;
const { Text } = Typography;

const Features = () => {
  const [form] = Form.useForm();
  const [valueForm] = Form.useForm();
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [valueModalVisible, setValueModalVisible] = useState(false);
  const [editingFeature, setEditingFeature] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [searchText, setSearchText] = useState('');

  // فرض می‌کنیم این توابع به API متصل هستند
  const fetchFeatures = async () => {
    setLoading(true);
    try {
      // API call به جای این خط
      const response = await fetch('/api/features');
      const data = await response.json();
      setFeatures(data);
    } catch (error) {
      message.error('خطا در دریافت ویژگی‌ها');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = async (values) => {
    try {
      // API call
      await fetch('/api/features', {
        method: 'POST',
        body: JSON.stringify({
          ...values,
          values: [] // مقادیر خالی برای ویژگی جدید
        }),
      });
      message.success('ویژگی با موفقیت اضافه شد');
      setModalVisible(false);
      form.resetFields();
      fetchFeatures();
    } catch (error) {
      message.error('خطا در افزودن ویژگی');
    }
  };

  const handleEdit = async (values) => {
    try {
      // API call
      await fetch(`/api/features/${editingFeature.id}`, {
        method: 'PUT',
        body: JSON.stringify(values),
      });
      message.success('ویژگی با موفقیت ویرایش شد');
      setModalVisible(false);
      setEditingFeature(null);
      form.resetFields();
      fetchFeatures();
    } catch (error) {
      message.error('خطا در ویرایش ویژگی');
    }
  };

  const handleDelete = async (id) => {
    try {
      // API call
      await fetch(`/api/features/${id}`, {
        method: 'DELETE',
      });
      message.success('ویژگی با موفقیت حذف شد');
      fetchFeatures();
    } catch (error) {
      message.error('خطا در حذف ویژگی');
    }
  };

  const handleAddValue = async (values) => {
    try {
      const updatedFeature = {
        ...selectedFeature,
        values: [...selectedFeature.values, values.value]
      };
      // API call
      await fetch(`/api/features/${selectedFeature.id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedFeature),
      });
      message.success('مقدار با موفقیت اضافه شد');
      setValueModalVisible(false);
      valueForm.resetFields();
      fetchFeatures();
    } catch (error) {
      message.error('خطا در افزودن مقدار');
    }
  };

  const handleDeleteValue = async (featureId, value) => {
    try {
      const feature = features.find(f => f.id === featureId);
      const updatedFeature = {
        ...feature,
        values: feature.values.filter(v => v !== value)
      };
      // API call
      await fetch(`/api/features/${featureId}`, {
        method: 'PUT',
        body: JSON.stringify(updatedFeature),
      });
      message.success('مقدار با موفقیت حذف شد');
      fetchFeatures();
    } catch (error) {
      message.error('خطا در حذف مقدار');
    }
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  const columns = [
    {
      title: 'عنوان',
      dataIndex: 'title',
      key: 'title',
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.title.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'نوع',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        const types = {
          select: 'انتخابی',
          color: 'رنگ',
          size: 'سایز',
          text: 'متنی'
        };
        return types[type] || type;
      }
    },
    {
      title: 'تعداد مقادیر',
      dataIndex: 'values',
      key: 'values',
      render: (values) => values?.length || 0,
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? 'فعال' : 'غیرفعال'}
        </Tag>
      ),
    },
    {
      title: 'عملیات',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<SettingOutlined />}
            onClick={() => {
              setSelectedFeature(record);
              setValueModalVisible(true);
            }}
          >
            مدیریت مقادیر
          </Button>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingFeature(record);
              form.setFieldsValue(record);
              setModalVisible(true);
            }}
          >
            ویرایش
          </Button>
          <Popconfirm
            title="آیا از حذف این ویژگی اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              حذف
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title="مدیریت ویژگی‌ها"
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingFeature(null);
            form.resetFields();
            setModalVisible(true);
          }}
        >
          افزودن ویژگی
        </Button>
      }
    >
      <Input.Search
        placeholder="جستجو در ویژگی‌ها"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16 }}
      />

      <Table
        columns={columns}
        dataSource={features}
        loading={loading}
        rowKey="id"
      />

      {/* مودال افزودن/ویرایش ویژگی */}
      <Modal
        title={editingFeature ? 'ویرایش ویژگی' : 'افزودن ویژگی جدید'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingFeature(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={editingFeature ? handleEdit : handleAdd}
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[{ required: true, message: 'لطفاً عنوان را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="type"
            label="نوع"
            rules={[{ required: true, message: 'لطفاً نوع را انتخاب کنید' }]}
          >
            <Select>
              <Select.Option value="select">انتخابی</Select.Option>
              <Select.Option value="color">رنگ</Select.Option>
              <Select.Option value="size">سایز</Select.Option>
              <Select.Option value="text">متنی</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="status"
            label="وضعیت"
            initialValue="active"
          >
            <Select>
              <Select.Option value="active">فعال</Select.Option>
              <Select.Option value="inactive">غیرفعال</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingFeature ? 'ویرایش' : 'افزودن'}
              </Button>
              <Button onClick={() => {
                setModalVisible(false);
                setEditingFeature(null);
                form.resetFields();
              }}>
                انصراف
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* مودال مدیریت مقادیر */}
      <Modal
        title={`مدیریت مقادیر برای ${selectedFeature?.title}`}
        open={valueModalVisible}
        onCancel={() => {
          setValueModalVisible(false);
          setSelectedFeature(null);
          valueForm.resetFields();
        }}
        width={800}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="افزودن مقدار جدید" key="1">
            <Form
              form={valueForm}
              layout="vertical"
              onFinish={handleAddValue}
            >
              <Form.Item
                name="value"
                label="مقدار"
                rules={[{ required: true, message: 'لطفاً مقدار را وارد کنید' }]}
              >
                {selectedFeature?.type === 'color' ? (
                  <Input type="color" />
                ) : (
                  <Input />
                )}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  افزودن مقدار
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="لیست مقادیر" key="2">
            <List
              dataSource={selectedFeature?.values || []}
              renderItem={value => (
                <List.Item
                  actions={[
                    <Popconfirm
                      title="آیا از حذف این مقدار اطمینان دارید؟"
                      onConfirm={() => handleDeleteValue(selectedFeature.id, value)}
                      okText="بله"
                      cancelText="خیر"
                    >
                      <Button type="link" danger>حذف</Button>
                    </Popconfirm>
                  ]}
                >
                  {selectedFeature?.type === 'color' ? (
                    <Space>
                      <div style={{
                        width: 20,
                        height: 20,
                        backgroundColor: value,
                        border: '1px solid #ddd',
                        borderRadius: 4
                      }} />
                      <Text>{value}</Text>
                    </Space>
                  ) : (
                    <Text>{value}</Text>
                  )}
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </Card>
  );
};

export default Features;