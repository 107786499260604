import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Select,
  message,
} from "antd";
import api from "../../services/api";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [plan, setPlan] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // دریافت نقش کاربر فعلی
  const fetchCurrentUserRole = async () => {
    try {
      const response = await api.get("panel/user/info?includes[]=immigration_file");
      setCurrentUserRole(response.data.data.role);
    } catch (error) {
      message.error("خطا در دریافت نقش کاربر");
      console.error(error);
    }
  };

  // دریافت لیست کاربران، نقش‌ها و برنامه‌ها
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get("panel/users?includes[]=immigration_file");
      setUsers(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت لیست کاربران");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get("panel/roles");
      setRoles(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت نقش‌ها");
      console.error(error);
    }
  };

  const fetchPlan = async () => {
    try {
      const response = await api.get("panel/plan");
      setPlan(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت برنامه‌ها");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCurrentUserRole();
    fetchUsers();
    fetchRoles();
    fetchPlan();
  }, []);

  const handleEdit = (user) => {
    setEditingUser(user);
    form.setFieldsValue({
      name: user.name,
      email: user.email,
      mobile: user.mobile,
      plan_id: user.plan?.id,
      passport_number: user.passport_number,
      started_at: user.started_at,
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (userId) => {
    try {
      await api.delete(`panel/users/${userId}`);
      message.success("کاربر با موفقیت حذف شد");
      fetchUsers();
    } catch (error) {
      message.error("خطا در حذف کاربر");
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (editingUser) {
        const res = await api.post(`panel/users/${editingUser.id}?_method=PUT`, {
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          passport_number: values.passport_number,
          password: values.password,
        });
        if (res?.data?.data?.id && currentUserRole === "super_admin") {
          await api.post("panel/roles/assignRoleToUser", {
            user_id: res.data.data.id,
            role_id: values.role,
          });
        }
        message.success("کاربر با موفقیت ویرایش شد");
      } else {
        const res = await api.post("panel/users", values);
        if (res?.data?.data?.id && currentUserRole === "super_admin") {
          await api.post("panel/roles/assignRoleToUser", {
            user_id: res.data.data.id,
            role_id: values.role,
          });
        }
        message.success("کاربر با موفقیت ایجاد شد");
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchUsers();
    } catch (error) {
      message.error("خطا در ذخیره کاربر");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "نام",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ایمیل",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "موبایل",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "نقش",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleEdit(record)}>
            ویرایش
          </Button>
          <Popconfirm
            title="آیا از حذف این کاربر اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
          >
            <Button type="primary" danger>
              حذف
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <div style={{ marginBottom: "16px" }}>
        <Button
          type="primary"
          onClick={() => {
            setEditingUser(null);
            form.resetFields();
            setIsModalVisible(true);
          }}
        >
          ایجاد کاربر جدید
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
      />

<Modal
  title={editingUser ? "ویرایش کاربر" : "ایجاد کاربر جدید"}
  open={isModalVisible}
  onCancel={() => setIsModalVisible(false)}
  footer={null}
>
  <Form form={form} layout="vertical" onFinish={handleSubmit}>
    <Form.Item
      name="name"
      label="نام"
      rules={[{ required: true, message: "لطفا نام را وارد کنید" }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="email"
      label="ایمیل"
      rules={[
        { required: true, message: "لطفا ایمیل را وارد کنید" },
        { type: "email", message: "فرمت ایمیل صحیح نیست" },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item name="mobile" label="موبایل">
      <Input />
    </Form.Item>

    {currentUserRole === "super_admin" && (
      <Form.Item name="role" label="نقش">
        <Select allowClear>
          {roles.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )}

    <Form.Item name="plan_id" label="برنامه مهاجرتی">
      <Select allowClear>
        {plan.map((plan) => (
          <Select.Option key={plan.id} value={plan.id}>
            {plan.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>

    <Form.Item name="passport_number" label="شماره پاسپورت">
      <Input />
    </Form.Item>

    <Form.Item name="started_at" label="تاریخ شروع پرونده">
      <Input />
    </Form.Item>

    <Form.Item
      name="password"
      label="رمز عبور"
      rules={[
        !editingUser && { required: true, message: "لطفا رمز عبور را وارد کنید" },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item>
      <Space>
        <Button type="primary" htmlType="submit">
          {editingUser ? "ویرایش" : "ایجاد"}
        </Button>
        <Button onClick={() => setIsModalVisible(false)}>انصراف</Button>
      </Space>
    </Form.Item>
  </Form>
</Modal>

    </div>
  );
};

export default UsersPage;
