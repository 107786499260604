// components/products/steps/FilesMedia.js
import React from 'react';
import { Form, Upload, Button } from 'antd';
import { PictureOutlined, UploadOutlined } from '@ant-design/icons';

const FilesMedia = ({ data, updateData }) => {
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onValuesChange = (_, allValues) => {
    updateData(allValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="mainImage"
        label="تصویر اصلی"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'تصویر اصلی الزامی است' }]}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          beforeUpload={() => false}
        >
          <div>
            <PictureOutlined />
            <div style={{ marginTop: 8 }}>آپلود تصویر</div>
          </div>
        </Upload>
      </Form.Item>

      <Form.Item
        name="gallery"
        label="گالری تصاویر"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          listType="picture-card"
          multiple
          beforeUpload={() => false}
        >
          <div>
            <PictureOutlined />
            <div style={{ marginTop: 8 }}>آپلود تصویر</div>
          </div>
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default FilesMedia;