// components/products/steps/PriceStock.js
import React from 'react';
import { Form, InputNumber, Select, Switch } from 'antd';

const { Option } = Select;

const PriceStock = ({ data, updateData }) => {
  const [form] = Form.useForm();

  const onValuesChange = (_, allValues) => {
    updateData(allValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="price"
        label="قیمت (تومان)"
        rules={[{ required: true, message: 'قیمت الزامی است' }]}
      >
        <InputNumber
          style={{ width: '100%' }}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item
        name="discountPrice"
        label="قیمت با تخفیف (تومان)"
      >
        <InputNumber
          style={{ width: '100%' }}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item
        name="stock"
        label="موجودی"
        rules={[{ required: true, message: 'موجودی الزامی است' }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>

      <Form.Item
        name="stockStatus"
        label="وضعیت موجودی"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default PriceStock;