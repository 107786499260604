// ConsultationList.js
import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select, 
  Space, 
  Tag,
  Popconfirm,
  message, 
  DatePicker
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SettingOutlined, CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from "../../services/api"
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


const { Option } = Select;
const { TextArea } = Input;

const ConsultationList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [statusForm] = Form.useForm();
  const [editingId, setEditingId] = useState(null);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const navigate = useNavigate();
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [meetingForm, setMeetingForm] = useState({
    title: '',
    body: '',
    admin_note: '',
    date: null,
  });

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=Consultation&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: '',
      body: '',
      admin_note: '',
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format("YYYY-MM-DD HH:mm:ss");
      
      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        consultation_id: selectedMeetingFileId,
      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: '',
        body: '',
        admin_note: '',
        date: null,
      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };

  
  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=Consultation&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };
  
  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        consultation_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };
  
  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];
  




  /////////////
  const fetchConsultations = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/panel/consultation`);
      setData(response.data.data); 
    } catch (error) {
      message.error('خطا در دریافت اطلاعات');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConsultations();
  }, []);

  const showModal = (record = null) => {
    setEditingId(record?.id);
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
    setModalVisible(true);
  };

  const showStatusModal = (record) => {
    setEditingId(record.id);
    statusForm.setFieldsValue({
      status: record.status,
      admin_message: record.admin_message
    });
    setStatusModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/panel/consultation/${id}`);
      message.success('درخواست با موفقیت حذف شد');
      fetchConsultations();
    } catch (error) {
      message.error('خطا در حذف درخواست');
      console.error('Error deleting consultation:', error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      
        await api.post('/panel/consultation', values);
      
      
      message.success(   "درخواست با موفقیت ثبت شد" );
      setModalVisible(false);
      fetchConsultations();
    } catch (error) {
      message.error('خطا در ثبت اطلاعات');
      console.error('Error submitting form:', error);
    }
  };

  const handleStatusSubmit = async (values) => {
    try {
      await api.post(`/panel/consultation/${editingId}?_method=PUT`, values);
      message.success('وضعیت با موفقیت بروزرسانی شد');
      setStatusModalVisible(false);
      fetchConsultations();
    } catch (error) {
      message.error('خطا در بروزرسانی وضعیت');
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    {
      title: 'شناسه',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'نام',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ایمیل',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'موبایل',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'نوع',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Tag color={type === 'online' ? 'blue' : 'green'}>
          {type === 'online' ? 'آنلاین' : 'حضوری'}
        </Tag>
      ),
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'pending' ? 'orange' : 'green'}>
          {status === 'pending' ? 'در انتظار' : 'انجام شده'}
        </Tag>
      ),
    },
    {
      title: 'پیام ادمین',
      dataIndex: 'admin_message',
      key: 'admin_message',
      ellipsis: true,
    },
    {
      title: 'تاریخ ایجاد',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'عملیات',
      key: 'action',
      render: (_, record) => (
        <Space>
          
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => showStatusModal(record)}
          />
          <Popconfirm
            title="آیا از حذف این درخواست اطمینان دارید؟"
            onConfirm={() => handleDelete(record.id)}
            okText="بله"
            cancelText="خیر"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
          <Button
          className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          >
            یادداشت
          </Button>
          <Button
          className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          >
            جلسه
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => showModal()}
        >
          افزودن درخواست جدید
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1000, // Set the horizontal scroll width
        }}
        rowKey="id"
        loading={loading}
      />

      <Modal
        title={editingId ? 'ویرایش درخواست' : 'درخواست جدید'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="نام"
            rules={[{ required: true, message: 'لطفا نام را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="ایمیل"
            rules={[
              { required: true, message: 'لطفا ایمیل را وارد کنید' },
              { type: 'email', message: 'ایمیل معتبر نیست' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mobile"
            label="موبایل"
            rules={[{ required: true, message: 'لطفا موبایل را وارد کنید' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="type"
            label="نوع مشاوره"
            rules={[{ required: true, message: 'لطفا نوع مشاوره را انتخاب کنید' }]}
          >
            <Select>
              <Option value="online">آنلاین</Option>
              <Option value="in_person">حضوری</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingId ? 'ویرایش' : 'ثبت'}
              </Button>
              <Button onClick={() => setModalVisible(false)}>انصراف</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="ویرایش وضعیت"
        open={statusModalVisible}
        onCancel={() => setStatusModalVisible(false)}
        footer={null}
      >
        <Form
          form={statusForm}
          layout="vertical"
          onFinish={handleStatusSubmit}
        >
          <Form.Item
            name="status"
            label="وضعیت"
            rules={[{ required: true, message: 'لطفا وضعیت را انتخاب کنید' }]}
          >
            <Select>
              <Option value="pending">در انتظار</Option>
              <Option value="completed">انجام شده</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="admin_message"
            label="پیام ادمین"
            rules={[{ required: true, message: 'لطفا پیام را وارد کنید' }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                ثبت تغییرات
              </Button>
              <Button onClick={() => setStatusModalVisible(false)}>انصراف</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) => setMeetingForm({ ...meetingForm, title: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) => setMeetingForm({ ...meetingForm, body: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) => setMeetingForm({ ...meetingForm, admin_note: e.target.value })}
            />
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={!meetingForm.title || !meetingForm.date}
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};

export default ConsultationList;