import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const AddBlog = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500;

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/category");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  //   const handleUpload = async (file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     setUploading(true);
  //     try {
  //       const response = await api.post("/panel/upload", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       return response.data.url;
  //     } catch (error) {
  //       message.error("خطا در آپلود تصویر");
  //       return "";
  //     } finally {
  //       setUploading(false);
  //     }
  //   };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      //   if (values.thumb?.fileList?.[0]?.originFileObj) {
      //     formData.append("thumb", values.thumb.fileList[0].originFileObj);
      //   }

      formData.append("title", values.title);
      formData.append("en_title", values.en_title);

      formData.append("description", values.description);
      formData.append("salary", values.salary);
      formData.append("country", values.country);
      formData.append("type", values.type);
      formData.append("tag", values.tag);
      formData.append("categories[]", values.category_id);
      formData.append("body", editorContent);

      await api.post("/panel/career-opportunity", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("فرصت شغلی با موفقیت ایجاد شد");
      navigate("/carreers");
    } catch (error) {
      message.error("خطا در ایجاد فرصت شغلی");
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">افزودن فرصت شغلی</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="en_title"
            label="عنوان انگلیسی"
            rules={[
              {
                required: true,
                message: "لطفا عنوان انگلیسی را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="type"
                label=" نوع"
                rules={[
                  {
                    required: true,
                    message: "لطفا نوع را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="salary"
                label=" حقوق"
                rules={[
                  {
                    required: true,
                    message: "لطفا حقوق را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="country"
                label=" کشور"
                rules={[
                  {
                    required: true,
                    message: "لطفا کشور را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tag"
                label=" تگ"
                rules={[
                  {
                    required: true,
                    message: "لطفا تگ را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="category_id"
            label="دسته‌بندی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
              mode="multiple" // Add this line to enable multiple selection
            />
          </Form.Item>

          {/* <Form.Item
            name="thumb"
            label="تصویر بنر"
            rules={[
              {
                required: true,
                message: "لطفا تصویر بنر را آپلود کنید",
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item> */}

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"CareerOpportunity"}
              height={1000}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ثبت
            </Button>
            <Button
              onClick={() => navigate("/carreers")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AddBlog;
